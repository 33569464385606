import { AES, enc } from 'crypto-js'
import indefinite from 'indefinite'
import Cookies from 'js-cookie'
import _ from 'lodash'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { toast } from 'react-toastify'
import { Table } from 'reactstrap'
import { REACT_APP_STORE_AUTH0_CLIENT_ID, REACT_APP_STORE_AUTH0_DOMAIN } from '../constants'

export { indefinite }

export const isArrayUnique = myArray => {
  return myArray.length === new Set(myArray).size
}

export const legacyLogOut = () => {
  // preserve site wall confirmation
  const confirmedAt = localStorage.getItem('confirmation_wall_accepted_at')

  // Only remove localStorage keys for the account
  localStorage.removeItem('coop_def')
  localStorage.removeItem('ecaid_def')
  localStorage.removeItem('eclid_def')
  localStorage.removeItem('ecpid_def')
  localStorage.removeItem('expiry_def')
  localStorage.removeItem('location_def')
  localStorage.removeItem('shop_def')
  localStorage.removeItem('account_credit_def')
  localStorage.removeItem('login_redirect_link_selected')
  localStorage.removeItem('login_redirect_link')
  localStorage.removeItem('custom_login_url')
  localStorage.removeItem('currentlocationId')
  localStorage.removeItem('sc')
  localStorage.removeItem('user_token')
  localStorage.removeItem('email')
  localStorage.removeItem('user_id')
  localStorage.removeItem('filteredCartItems')
  localStorage.setItem('confirmation_wall_accepted_at', confirmedAt)
  localStorage.removeItem('authToken')
}

export const enterpriseLogOut = () => {
  const decryptData = encryptedData => {
    const passphrase = REACT_APP_STORE_AUTH0_CLIENT_ID
    const bytes = AES.decrypt(encryptedData, passphrase)
    return bytes.toString(enc.Utf8)
  }

  const encryptedIdToken = Cookies.get('idToken')
  const encryptedProvider = Cookies.get('userProvider')
  const idToken = encryptedIdToken ? decryptData(encryptedIdToken) : null
  const providerName = encryptedProvider ? decryptData(encryptedProvider) : null

  if (!idToken || !providerName) {
    return false
  }

  // We are only allowed one back channel logout uri in Auth0
  // So we are returning early so you can still log out of the system
  if (process.env.NODE_ENV === 'development') {
    document.location.href = '/log-in'
    return
  }

  Cookies.remove('idToken')
  Cookies.remove('userProvider')

  const isStaging = window.location.origin.includes('staging')

  let returnToUrl = `${window.location.origin}/log-in`

  if (providerName === 'DevTestOneLogin') {
    returnToUrl = encodeURIComponent('https://brandit-dev.onelogin.com/oidc/2/logout')
  }

  if (providerName === 'SVPOneLogin') {
    returnToUrl = encodeURIComponent('https://svp.onelogin.com/oidc/2/logout')
  }

  if (providerName === 'VidaPay') {
    if (process.env.NODE_ENV === 'productions') {
      returnToUrl = encodeURIComponent('https://id.vidapay.com/connect/endsession')
    }
    if (isStaging || process.env.NODE_ENV === 'development') {
      returnToUrl = encodeURIComponent('https://id.vidapay.staging.tcetra.dev/connect/endsession')
    }
  }

  const logoutUrl = `https://${REACT_APP_STORE_AUTH0_DOMAIN}/v2/logout?client_id=${REACT_APP_STORE_AUTH0_CLIENT_ID}&returnTo=${returnToUrl}&id_token_hint=${idToken}`
  window.location.href = logoutUrl

  return true
}

// Format types:
//  default:                  (555) 555 - 5555
//  default_spaces:           (555) 555 - 5555
//  dot:                      555.555.5555
//  default_no_parenthesis:   555 555-5555
//  all_dashes:               555-555-5555
//  all_spaces:               555 555 5555
//  spaces_parentheses:      (555) 555 5555

// For the New DP System
export const formatPhoneNumber = (incomingPhoneNumber, formatType = 'default_no_spaces', can_vanity_phone) => {
  // Early returns
  if (!incomingPhoneNumber) return ''
  if (can_vanity_phone) {
    return incomingPhoneNumber.replaceAll(/[!@$%ˆ&_={}]/g, '')
  }

  // Get clean digits first
  const digits = incomingPhoneNumber.replace(/\D/g, '').slice(0, 10)

  // Split into parts (always do this regardless of length)
  const parts = {
    first: digits.slice(0, 3),
    middle: digits.slice(3, 6),
    last: digits.slice(6, 10)
  }

  // Format mapping object for cleaner logic
  const formatters = {
    default: parts => {
      if (!parts.first) return ''
      if (!parts.middle) return `(${parts.first})`
      if (!parts.last) return `(${parts.first}) ${parts.middle}`
      return `(${parts.first}) ${parts.middle}-${parts.last}`
    },

    default_spaces: parts => {
      if (!parts.first) return ''
      if (!parts.middle) return `(${parts.first})`
      if (!parts.last) return `(${parts.first}) ${parts.middle}`
      return `(${parts.first}) ${parts.middle} - ${parts.last}`
    },

    dot: parts => {
      if (!parts.first) return ''
      if (!parts.middle) return parts.first
      if (!parts.last) return `${parts.first}.${parts.middle}`
      return `${parts.first}.${parts.middle}.${parts.last}`
    },

    default_no_parenthesis: parts => {
      if (!parts.first) return ''
      if (!parts.middle) return parts.first
      if (!parts.last) return `${parts.first}-${parts.middle}`
      return `${parts.first} ${parts.middle}-${parts.last}`
    },

    all_dashes: parts => {
      if (!parts.first) return ''
      if (!parts.middle) return parts.first
      if (!parts.last) return `${parts.first}-${parts.middle}`
      return `${parts.first}-${parts.middle}-${parts.last}`
    },

    all_spaces: parts => {
      if (!parts.first) return ''
      if (!parts.middle) return parts.first
      if (!parts.last) return `${parts.first} ${parts.middle}`
      return `${parts.first} ${parts.middle} ${parts.last}`
    },

    spaces_parentheses: parts => {
      if (!parts.first) return ''
      if (!parts.middle) return `(${parts.first})`
      if (!parts.last) return `(${parts.first}) ${parts.middle}`
      return `(${parts.first}) ${parts.middle} ${parts.last}`
    }
  }

  // Default to returning cleaned digits if format type isn't recognized
  const formatter = formatters[formatType] || (() => digits)
  return formatter(parts)
}

export const isPhoneNumber = value => {
  if (!value) return false

  // This will remove all non-digit characters.
  const strippedValue = value.replace(/\D/g, '')

  // Note: This pattern only checks for the common NANP format without country code.
  const phoneNumberRegex = /^1?([2-9][0-8][0-9])([2-9][0-9]{2})([0-9]{4})$/

  return phoneNumberRegex.test(strippedValue)
}

export const titleizeField = fieldname => {
  return fieldname
    .split('_')
    .map(word => `${word[0].toUpperCase()}${word.slice(1)}`)
    .join(' ')
}

// accepts arrays of managed and traditional addresses and returns a merged array
export const resolveAddresses = (traditionalAddresses, managedAddresses, portal, hideCreateShippingAddress) => {
  const allAddresses = _.concat(managedAddresses, traditionalAddresses)
  let addressesForList = []
  if (portal.managed_address_book || portal.hybrid_address) {
    // if portal has hybrid address enabled, include traditional addresses
    if (portal.hybrid_address === true && !hideCreateShippingAddress) {
      addressesForList = allAddresses
    } else {
      addressesForList = _.concat(managedAddresses, addressesForList)
    }
  } else {
    addressesForList = traditionalAddresses
  }
  return addressesForList
}

// accepts either a smart or traditional address and returns a simple object with standard keys
export const standardizeAddress = address => {
  return {
    ...address,
    address_line_1: address.address_line_1 ? address.address_line_1 : address.address1,
    address_line_2: address.address_line_2 ? address.address_line_2 : address.address2,
    city: address.city,
    state: address.state,
    zip_code: address.zip_code ? address.zip_code : address.zip,
    country: address.country_code ? address.country_code : address.country
  }
}

// re-formats a smart address to have standard fields and prefies ID with "smart"
export const reformatSmartAddresses = smartAddresses => {
  return smartAddresses.map(address => {
    const formattedAddress = {
      ...address,
      title: address.name,
      full_name: '',
      address_line_1: address.address1,
      address_line_2: address.address2,
      city: address.city,
      state: address.state,
      zip_code: address.zip
    }

    if (address.address1) {
      return {
        ...formattedAddress,
        id: `managed${address.id}`
      }
    } else {
      return formattedAddress
    }
  })
}

// HTML formatters for address
export const addressLabel = (address, hidePhone = false) => {
  const addressIsSmart = Object.keys(address).includes('address1')
  return addressIsSmart ? smartAddressLabel(address) : traditionalAddressLabel(address, hidePhone)
}

const smartAddressLabel = address => {
  return (
    <div className="address-label address-label-smart">
      <strong>{address.name}</strong>
      <br />
      {address.full_name ? (
        <>
          <b>{address.full_name}</b>
          <br />
        </>
      ) : null}
      {address.full_address}
      <br />
    </div>
  )
}

const traditionalAddressLabel = (address, hidePhone = false) => {
  if (!address || _.isEmpty(address)) {
    return 'Please select...'
  }

  return (
    <div className="address-label address-label-traditional">
      <strong>
        {address.full_name}
        {address.company_name ? <>, {address.company_name}</> : null}
      </strong>
      <br />
      {address.address_line_1}
      {address.address_line_2 ? (
        <>
          <br />
          {address.address_line_2}
        </>
      ) : null}
      <br />
      {address.city}, {address.state} {address.zip_code}
      {hidePhone ? null : (
        <>
          <br />
          {address.address_phone}
        </>
      )}
    </div>
  )
}

// returns searchable string for address options in react-select
export const addressLabelString = (address, props) => {
  return (address.full_name || address.name) + (address.address_line_1 || address.address1)
}

// used to merge data from validated data via validation API into an existing address object
export const mergeAddressData = (addressData, validatedAddressData) => {
  return Object.assign({}, addressData, {
    address_line_1: validatedAddressData.address_line1,
    address_line_2: validatedAddressData.address_line2,
    city: validatedAddressData.city_locality,
    zip_code: validatedAddressData.postal_code,
    state: validatedAddressData.state_province,
    is_valid: true
  })
}

// uses bootstrap table to format an address
export const formatAddress = (address, label, isInternationalShipping) => {
  return (
    <Table borderless responsive size="sm">
      <thead>
        <tr>
          <th>{label}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{address.address_line_1}</td>
        </tr>
        {address.address_line_2 ? (
          <tr>
            <td>{address.address_line_2}</td>
          </tr>
        ) : null}
        <tr>
          <td>
            {address.city ? `${address.city}, ` : null}
            {address.state}
          </td>
        </tr>
        <tr>
          <td>{address.zip_code}</td>
        </tr>
        {isInternationalShipping ? (
          <tr>
            <td>{address.country}</td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  )
}

export const formatDate = date => {
  const result = new Date(date)
  return result.toLocaleString()
}

export const months = [
  {
    label: 'Please Select',
    value: '-1'
  },
  {
    value: 'January',
    label: 'January'
  },
  {
    value: 'February',
    label: 'February'
  },
  {
    value: 'March',
    label: 'March'
  },
  {
    value: 'April',
    label: 'April'
  },
  {
    value: 'May',
    label: 'May'
  },
  {
    value: 'June',
    label: 'June'
  },
  {
    value: 'July',
    label: 'July'
  },
  {
    value: 'August',
    label: 'August'
  },
  {
    value: 'September',
    label: 'September'
  },
  ,
  {
    value: 'October',
    label: 'October'
  },
  {
    value: 'November',
    label: 'November'
  },
  {
    value: 'December',
    label: 'December'
  }
]

export const checkLoginErrors = () => {
  if (localStorage.getItem('loginError') === 'not_active') {
    toast.error('Your account is not active.', {
      position: toast.POSITION.TOP_CENTER,
      type: toast.TYPE.DANGER,
      className: 'error-toast',
      autoClose: 3000
    })
    localStorage.setItem('loginError', '')
  } else if (localStorage.getItem('loginError') === 'token_expired') {
    toast.error('Your session is expired, please log in.', {
      position: toast.POSITION.TOP_CENTER,
      type: toast.TYPE.DANGER,
      className: 'error-toast',
      autoClose: 3000
    })
    localStorage.setItem('loginError', '')
  }
}

export const validationRules = {
  full_name: {
    required: true,
    maxLength: 50
  },
  address_line_1: {
    required: true,
    maxLength: 60
  },
  address_line_2: {
    required: false
  },
  city: {
    required: true,
    maxLength: 40
  },
  company_name: {
    required: false,
    maxLength: 50
  },
  state: {
    required: false
  },
  zip_code: {
    required: true,
    maxLength: 20
  },
  country: {
    required: false
  },
  address_phone: {
    required: true,
    validate: (value, isInternationalShipping) => {
      if (isInternationalShipping) {
        return isPossiblePhoneNumber(value)
      } else {
        return isPhoneNumber(value)
      }
    }
  }
}

export const validateAddressLines = (fieldName, addressLine1, addressLine2, currentErrors) => {
  const totalLength = addressLine1.length + addressLine2.length
  const newErrors = { ...currentErrors }
  const errorMessage = 'Please ensure your Address is within 60 characters'

  if (totalLength > 60) {
    newErrors[fieldName] = errorMessage
  } else {
    newErrors[fieldName] = null
  }

  return newErrors
}
