import React, { useState, useEffect, useRef } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { Helmet } from 'react-helmet'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'
import { Route, Switch, Redirect, withRouter, matchPath } from 'react-router-dom'
import { toast } from 'react-toastify'
import { compose } from 'redux'
import {
  fetchCartItems,
  fetchLocationCategories,
  fetchPages,
  fetchPortal,
  fetchUser,
  fetchUserCredit,
  fetchCheckoutForm,
  setLoading,
  fetchCustomerAddresses,
  fetchManagedCustomerAddresses,
  setCurrentLocation,
  clearCoupons,
  fetchCurrentLocation
} from './actions'
import { setSessionToken } from './actions/sessions'
import samplePortalCss from './assets/scss/sample_portal_css.css'
import Cart from './components/Cart/Cart'
import { setAppliedCoupon } from './components/Checkout/checkoutFunctions'

// ** Components for routes **
import CheckoutPage from './components/Checkout/CheckoutPage'
import ContentLoader from './components/global/loaders/ContentLoader'
const ContactPage = React.lazy(() => import('./components/Contact/ContactPage'))
const Dashboard = React.lazy(() => import('./components/CustomerAccount/Dashboard/Dashboard'))
const Invoice = React.lazy(() => import('./components/Checkout/final-receipt/Invoice'))
import HomePage from './components/PortalPages/HomePage'
import PortalPage from './components/PortalPages/PortalPage'
const PageNotFound = React.lazy(() => import('./components/PageNotFound'))
import SsoSignInPage from './components/Sso/SsoSignInPage'
const SignInWrapper = React.lazy(() => import('./components/UserPages/SignInWrapper'))
const EditContactInformation = React.lazy(
  () => import('./components/CustomerAccount/Dashboard/EditContactInformation.js')
)
const CustomerAddress = React.lazy(() => import('./components/CustomerAccount/Dashboard/CustomerAddress'))
const MyOrders = React.lazy(() => import('./components/CustomerAccount/Dashboard/MyOrders'))
const MyCredit = React.lazy(() => import('./components/CustomerAccount/Dashboard/MyCredit'))
const MyDownloads = React.lazy(() => import('./components/CustomerAccount/Dashboard/MyDownloads'))
const MyOrderShow = React.lazy(() => import('./components/CustomerAccount/Dashboard/MyOrderShow'))
import ProductDetails from './components/Shop/ProductDetails'
import ProductList from './components/Shop/ProductList'
import Loading from './components/layouts/LoadingFallback'

// css
import './lib/embryoCss.js'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.css'

//layout
import HeaderOne from './components/Header/HeaderOne'
import FooterOne from './components/layouts/footers/FooterOne'

// Google Analytics
import { default as LegacyReactGA } from 'react-ga'
import ReactGA from 'react-ga4'
import { resolveHomePage } from './components/PortalPages'
import useVersionCheck from './hooks/useVersionCheck'
import { legacyLogOut, enterpriseLogOut } from './util/utils'

const Maintenance = Loadable({ loader: () => import('./components/Maintenance/MaintenanceMode'), loading: Loading })

toast.configure({
  autoClose: 2500,
  draggable: true,
  position: 'top-center'
})

const App = props => {
  const [portalLoading, setPortalLoading] = useState(true)
  const [locationsLoading, setLocationsLoading] = useState(false)
  const [homePage, setHomePage] = useState({})
  const [metaTags, setMetaTags] = useState([])
  const [showConfirmationWall, setShowConfirmationWall] = useState(false)
  const [sessionCustomCss, setSessionCustomCss] = useState(null)
  const [isPrivatizedLogin, setIsPrivatizedLogin] = useState(false)

  const { location, currentLocation, portal, pages, loading, currentUser } = props
  const prevLocationRef = useRef()

  // Use this hook to force logout when new version is deployed
  useVersionCheck()

  // Apply custom portal CSS after portal is loaded
  useEffect(() => {
    if (portal.custom_css_block && portal.custom_css_block !== sessionCustomCss) {
      setSessionCustomCss(portal.custom_css_block)
      applyCustomCss(portal.custom_css_block)
    }
  }, [portal.custom_css_block])

  const applyCustomCss = cssText => {
    const styleId = 'custom-css-style'
    let styleElement = document.getElementById(styleId)
    if (!styleElement) {
      styleElement = document.createElement('style')
      styleElement.id = styleId
      document.head.appendChild(styleElement)
    }
    styleElement.innerHTML = cssText
  }

  // Fetch portal data on load
  useEffect(() => {
    props
      .fetchPortal()
      .then(response => {
        // Google Analytics
        if (props.portal.has_google_analytics) {
          // Will use new code/package if available, otherwise use legacy code/package
          if (props.portal.google_analytics_tracking_code && props.portal.google_analytics_tracking_code.length > 0) {
            ReactGA.initialize(`${props.portal.google_analytics_tracking_code}`)
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
          } else {
            LegacyReactGA.initialize(`${props.portal.google_analytics_tracking_code}`)
            LegacyReactGA.pageview(window.location.pathname + window.location.search)
          }
        }
        setPortalLoading(false)
      })
      .catch(error => {
        console.error('Error fetching portal', error)
        props.history.push('/not_found')
      })
  }, [])

  // On app load, check localStorage for session token
  // and add event listener for postMessage to receive token from internal links that pass it
  // if logout=true is passed in the URL, clear the session token
  useEffect(() => {
    // Check if logout=true is passed in the URL
    const urlParams = new URLSearchParams(window.location.search)
    const logout = urlParams.get('logout')
    if (logout === 'true') {
      legacyLogOut()
      const isEnterpriseLogout = enterpriseLogOut()
      props.setLoading('session', false)
      // remove logout from params
      if (!isEnterpriseLogout) {
        props.history.push('/log-in')
      }
      return
    }

    // Check localStorage for session token
    const sessionToken = localStorage.getItem('authToken')
    if (sessionToken && !props.session) {
      props.setSessionToken(sessionToken)
    } else if (!sessionToken && props.loading.session) {
      props.setLoading('session', false)
    }

    // Handler for receiving postMessage, for when app is opened in new tab via link
    const handlePostMessage = event => {
      const allowedDomain = window.location.origin // only accept posts from the same domain

      if (event.origin === allowedDomain) {
        if (event.data.token) {
          localStorage.setItem('authToken', event.data.token) // Save the passed token to localStorage
          props.setSessionToken(event.data.token) // Update session token in Redux
        }
      }
    }

    // Add event listener for postMessage
    window.addEventListener('message', handlePostMessage, false)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handlePostMessage, false)
    }
  }, [])

  // fetch user after session is fetched
  // or when portal/location is fetched/changed
  useEffect(() => {
    if (props.session) {
      props
        .fetchUser()
        .then(resp => {})
        .catch(e => {
          console.error('Error fetching User')
        })
        .finally(() => {
          props.setLoading('session', false)
        })
    }
  }, [props.currentLocation.id, props.session, props.fetchUser, props.loading.session])

  // On URL path change
  useEffect(() => {
    // Get the previous location from useRef
    const prevLocation = prevLocationRef.current

    // Now we set the previous location to be the current one for the next render
    prevLocationRef.current = location.pathname

    // We only want to execute the code inside if this is not the first render
    // and the location has changed to or from the checkout
    if (
      prevLocation &&
      ((prevLocation.includes('check-out') && !location.pathname.includes('check-out')) ||
        (!prevLocation.includes('check-out') && location.pathname.includes('check-out')))
    ) {
      // reset coupons
      fetchCartAndClearCoupons()
    }
  }, [location.pathname])

  // fetch location data after portal is fetched
  useEffect(() => {
    if (props.portal.id && !props.loading.session) {
      // if there is a location in localStorage, fetch it on load
      const locationId = localStorage.getItem('currentlocationId')
      if (locationId && locationId !== 'undefined') {
        props.fetchCurrentLocation(locationId)
      } else {
        props.fetchCurrentLocation(props.portal.default_location.id)
      }
    }
  }, [props.portal.id, props.loading.session])

  // On Location change
  useEffect(() => {
    const locationId = currentLocation.id

    if (!locationId) return

    if (locationId && locationId > 0) {
      fetchLocationData(locationId)
    } else {
      console.error('No location found')
    }
  }, [currentLocation.id])

  // Check if user belongs to current location after order
  useEffect(() => {
    if (currentLocation?.id && !locationsLoading && props.locations?.length > 0) {
      const currentLocationValid = props.locations.some(loc => loc.id === currentLocation.id)
      if (!currentLocationValid) {
        props.setCurrentLocation(props.locations[0])
      }
    }
  }, [currentLocation, props.locations, locationsLoading])

  const fetchLocationData = locationId => {
    props.setLoading('pages', true)

    props.fetchLocationCategories(locationId)
    props.fetchCheckoutForm(locationId)

    props
      .fetchPages()
      .catch(error => {
        // props.history.push('/not_found')
        console.error('Error fetching pages', error)
      })
      .finally(() => {
        props.setLoading('pages', false)
      })
  }

  // On Pages change
  useEffect(() => {
    if (currentLocation.id) {
      const newHomePage = resolveHomePage(pages, currentLocation.id)
      setHomePage(newHomePage)
    }
  }, [pages])

  // On User change
  useEffect(() => {
    if (currentUser.id && props.portal.id) {
      checkConfirmationWall()

      // when user changes, check the locations they have access to.
      // If none, use the portal default location
      const userHasLocation = currentUser.all_user_locations.find(loc => loc.location_id === currentLocation.id)
      if (currentUser.all_user_locations.length === 0 || !userHasLocation) {
        props.fetchCurrentLocation(props.portal.default_location.id).then(() => {
          setLocationsLoading(false)
        })
      }
    } else if (props.portal.id && !props.loading.session) {
      // if user is not logged in (after the user has been fetched), use the default location from the portal
      props.fetchCurrentLocation(props.portal.default_location.id).then(() => {
        setLocationsLoading(false)
      })
    }
  }, [currentUser.id, props.portal.id, props.loading.session])

  // On User or Location change
  useEffect(() => {
    const isGuest = localStorage.getItem('guest_user_id') && !currentUser.id
    // Need to check guest or user to persist cart on refresh
    if (currentUser.id || isGuest) {
      if (currentUser.id) {
        props.fetchCustomerAddresses(currentUser.id, portal.id)
      }
      if ((props.portal.hybrid_address || props.portal.system_managed_address_book) && currentUser.id) {
        props.fetchManagedCustomerAddresses()
      }
      if (currentUser.id && currentLocation.id) {
        props.fetchUserCredit().then(() => {
          fetchCartAndClearCoupons()
        })
      } else {
        fetchCartAndClearCoupons()
      }
    }
  }, [currentUser.id, currentLocation.id])

  // On Portal load
  useEffect(() => {
    if (portal && portal.id) {
      buildMetaTags(portal)
      buildFavicon()
      checkConfirmationWall()
      setIsPrivatizedLogin(portal.portal_has_privatized_login_page)
    }
  }, [portal.id])

  const checkConfirmationWall = () => {
    // Confirmation Wall
    if (portal.portal_has_confirmation_wall) {
      const token = localStorage.getItem('confirmation_wall_accepted_at')
      const tokenIsExpired = new Date(token) < new Date(new Date().getTime() - 24 * 60 * 60 * 1000)

      // if user is logged in and token is present, ignore expiry
      if (props.currentUser.id && token) {
        localStorage.setItem('confirmation_wall_accepted_at', new Date())
        showConfirmationWall && setShowConfirmationWall(false)
      }
      // if token is not present, always ask to confirm
      else if (!token) {
        !showConfirmationWall && setShowConfirmationWall(true)
      }
      // if token is expired, only ask to confirm if user is not logged in
      else if (tokenIsExpired && !props.currentUser.id) {
        !showConfirmationWall && setShowConfirmationWall(true)
      }
    }
  }

  const handleAcceptTerms = () => {
    const now = new Date()
    localStorage.setItem('confirmation_wall_accepted_at', now)
    setShowConfirmationWall(false)
  }

  const fetchCartAndClearCoupons = () => {
    const user_id = props.currentUser.id
    props.fetchCartItems(user_id, props.portal, currentLocation, props.userCredits.coop_credit.remaining).then(() => {
      props.setAppliedCoupon('')
      props.clearCoupons()
    })
  }

  const buildFavicon = () => {
    if (props.portal.length === 0) {
      return []
    }
    const favicon = document.getElementById('favicon')
    if (favicon && props.portal.favicon) {
      favicon.href = props.portal.favicon.medium.url
    }
  }

  const buildMetaTags = portal => {
    const newMetaTags = resolveMetaTags(portal)
    setMetaTags(newMetaTags)
  }

  const renderHelmet = portal => {
    let metaTitle = false

    const handleStylesheetError = () => {
      console.error('Error loading custom CSS. Refreshing page.')
      window.location.reload()
    }

    return (
      <Helmet>
        {/* Add Portal CSS */}
        {portal && portal.stylesheet ? (
          <link rel="stylesheet" href={`/css/${portal.stylesheet}`} onError={handleStylesheetError} />
        ) : null}

        {/* Fall back to sample CSS if portal has no custom CSS block */}
        {portal ? <style type="text/css">{sessionCustomCss || `${samplePortalCss}`}</style> : null}

        {metaTags.map(tag => {
          if (tag.property === 'description') {
            return <meta name={tag.property} content={tag.value} key={tag.property} />
          } else if (tag.property !== 'title') {
            return <meta property={tag.property} content={tag.value} key={tag.property} />
          } else {
            metaTitle = true
            return <title key={tag.property}>{tag.value}</title>
          }
        })}
        {!metaTitle ? <title key={portal.name}>{portal.name}</title> : null}
      </Helmet>
    )
  }

  const shouldHideHeaderFooter = location => {
    const hideForPaths = [
      '/forgot-password',
      '/password-expired',
      '/reset-password/:token',
      '/sign-up',
      '/send-confirmation',
      '/log-in',
      '/help-signing-in',
      '/auth0_callback'
    ]

    // hide if user is being logged in
    if (props.sessionStatus === 'LOADING') return true

    return hideForPaths.some(path => {
      const match = matchPath(location.pathname, {
        path,
        exact: true
      })
      return match && match.isExact
    })
  }
  const userIsLoggedIn = currentUser?.id
  const loginUrl = `/${portal.custom_login_url || 'log-in'}`
  const hideHeaderFooter = shouldHideHeaderFooter(location)

  const appLoading = portalLoading || loading.customerGroups || locationsLoading || loading.signIn || loading.session
  _.isEmpty(portal) || _.isEmpty(currentLocation)

  if (appLoading && window.location.pathname !== '/not_found') {
    return <ContentLoader />
  } else if (portal.maintenance_mode) {
    return <Maintenance portal={portal} />
  }

  return (
    <div id="app-wrapper">
      {renderHelmet(portal)}

      {!hideHeaderFooter && <HeaderOne />}

      <main className="main-content">
        <div className="app-container">
          <div>{/* dummy div needed for portal accent stripe */}</div>
          <Switch>
            {/* Common (Always Public) Routes */}
            <Route exact path="/not_found" component={PageNotFound} />
            <Route exact path="/sso-log-in/:sso_id/:sso_token" component={SsoSignInPage} />
            <Route
              path={[
                '/forgot-password',
                '/password-expired',
                '/reset-password/:token',
                '/sign-up',
                '/send-confirmation',
                '/help-signing-in',
                '/auth0_callback'
              ]}
              render={props => <SignInWrapper {...props} homePage={homePage} publicPage={!isPrivatizedLogin} />}
            />

            {/* Use different sign-in page for private portals */}
            <Route
              exact
              path={loginUrl}
              render={props => <SignInWrapper {...props} homePage={homePage} publicPage={!isPrivatizedLogin} />}
            />

            {/* Private portals redirect when not logged in */}
            {isPrivatizedLogin && !userIsLoggedIn && !props.loading.session && (
              <Route path="/">
                <Redirect to={loginUrl} />
              </Route>
            )}

            {/* If portal is private, user must be logged in to access these routes */}
            {isPrivatizedLogin && !userIsLoggedIn ? null : (
              <>
                <Route
                  exact
                  path="/customer/account/dashboard"
                  render={props => <Dashboard {...props} currentLocation={currentLocation} />}
                />
                <Route exact path="/customer/account/profile" component={EditContactInformation} />
                <Route exact path="/customer/account/addresses" component={CustomerAddress} />
                <Route exact path="/customer/account/orders" component={MyOrders} />
                <Route exact path="/customer/account/credit-balance" component={MyCredit} />
                <Route exact path="/customer/account/downloads" component={MyDownloads} />
                <Route path="/order-details/:orderNumber/:orderId" component={MyOrderShow} />
                <Route path="/product/:product_id/:portal_id/:location_id" component={ProductDetails} />
                {/* <Route path="/product/:product_id/:portal_id" component={ProductDetails} /> */}
                <Route
                  path="/check-out"
                  render={props => <CheckoutPage {...props} currentLocation={currentLocation} />}
                />
                <Route path="/contact-us" component={ContactPage} />
                <Route
                  exact
                  path="/shop"
                  render={props => <ProductList {...props} currentLocation={currentLocation} />}
                />
                <Route
                  exact
                  path="/shop/:category_id"
                  render={props => <ProductList {...props} currentLocation={currentLocation} />}
                />
                <Route
                  exact
                  path="/shop/tag_filters/:tag_filter_id"
                  render={props => <ProductList {...props} currentLocation={currentLocation} />}
                />
                <Route path="/shop?" render={props => <ProductList {...props} currentLocation={currentLocation} />} />
                <Route exact path="/cart" render={props => <Cart {...props} currentLocation={currentLocation} />} />
                <Route exact path="/confirmation" component={Invoice} />

                {/* Render routes for portal pages (not location-specific pages) */}
                {pages
                  .filter(page => page.page_type !== 'Home' && page.location_id !== props.currentLocation.id)
                  .map(page => {
                    return (
                      <Route
                        path={page.active_url}
                        key={page.id}
                        render={props => <PortalPage {...props} page={page} />}
                      />
                    )
                  })}
                <Route exact path={homePage.active_url} render={props => <HomePage {...props} page={homePage} />} />
              </>
            )}

            {/* Fallback Routes */}
            <Route component={PageNotFound} />
          </Switch>
        </div>

        {/* Confirmation Wall overrides normal SweetAlert unless accepted */}
        {showConfirmationWall ? (
          <SweetAlert
            title=""
            onConfirm={handleAcceptTerms}
            onCancel={() => {
              window.location.href = 'https://www.google.com'
            }}
            showCancel={true}
            confirmBtnCssClass="mf-primary-btn"
            confirmBtnText="I Accept"
            closeOnClickOutside={false}
          >
            {portal.confirmation_wall_message}
          </SweetAlert>
        ) : (
          <SweetAlert
            {...props.sweetAlert}
            // default CSS style for confirm button
            confirmBtnCssClass={props.sweetAlert.confirmBtnCssClass || 'mf-primary-btn'}
          >
            {props.sweetAlert.alertMessage}
          </SweetAlert>
        )}
      </main>
      {!hideHeaderFooter && <FooterOne />}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentLocation: state.currentLocation,
    currentUser: state.currentUser,
    loading: state.loading,
    locations: state.locations,
    pages: state.pages,
    portal: state.portal,
    session: state.session,
    sessionStatus: state.status,
    sweetAlert: state.sweetAlert,
    userCredits: state.userCredits
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchPages,
    fetchPortal,
    fetchUserCredit,
    fetchUser,
    fetchCartItems,
    fetchLocationCategories,
    clearCoupons,
    setAppliedCoupon,
    fetchCustomerAddresses,
    fetchManagedCustomerAddresses,
    fetchCheckoutForm,
    fetchCurrentLocation,
    setSessionToken,
    setLoading,
    setCurrentLocation
  })
)(App)

const resolveMetaTags = portal => {
  if (!portal || portal.length === 0) return []

  const pushToTags = (value, properties) => {
    properties.forEach(property => {
      newMetaTags.push({ value, property })
    })
  }

  const newMetaTags = []

  const mappings = [
    { valueKey: 'meta_title', properties: ['title', 'og:title', 'twitter:title'] },
    { valueKey: 'meta_description', properties: ['description', 'og:description', 'twitter:description'] },
    { valueKey: 'meta_locale', properties: ['og:locale'] },
    { valueKey: 'meta_site_name', properties: ['og:site_name'] },
    { valueKey: 'meta_type', properties: ['og:type'] },
    { valueKey: 'meta_twitter_type', properties: ['twitter:type'] },
    { valueKey: 'meta_image_alt', properties: ['og:image:alt'] }
  ]

  mappings.forEach(mapping => {
    const value = portal[mapping.valueKey]
    if (value) {
      pushToTags(value, mapping.properties)
    }
  })

  if (portal.meta_image && portal.meta_image.small && portal.meta_image.small.url) {
    pushToTags(portal.meta_image.small.url, ['twitter:image', 'og:image'])
  }

  return newMetaTags
}
