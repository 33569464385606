import _ from 'lodash'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Input, Col, Row, Label, FormGroup } from 'reactstrap'
import AddressList from './AddressList'
import { setCustomerAddress, setBillingAddress } from './checkoutFunctions'

const CheckoutAddressPanel = ({ addressType, addresses, ...props }) => {
  const [sameAddressToggle, setSameAddressToggle] = useState(true)
  const [useShippingAsBilling, setUseShippingAsBilling] = useState(true)

  // If only one address, set as selected (only for shipping)
  useEffect(() => {
    if (addresses.length === 1 && addressType === 'delivery') {
      handleSelectAddress(addresses[0])
    }
  }, [])

  // when selected billing address changes, if sameAddressToggle is true, set billing to the same
  useEffect(() => {
    if (props.selectedBillingAddress.id !== props.selectedCustomerAddress.id && sameAddressToggle !== false) {
      setSameAddressToggle(false)
    }
  }, [props.selectedBillingAddress])

  // un-check use shipping as billing under certain conditions
  useEffect(() => {
    if (
      (props.manageAddresses === true && useShippingAsBilling === true) ||
      (props.newAddressMode === true && useShippingAsBilling === true)
    ) {
      setUseShippingAsBilling(false)
    }
  }, [props.newAddressMode, props.manageAddresses])

  const handleSelectAddress = (address, deselect = false) => {
    if (addressType === 'delivery') {
      props.setCustomerAddress(deselect ? {} : address).then(() => {
        const addressesAreSame = props.selectedBillingAddress.id === props.selectedCustomerAddress.id
        // additionally set billing if same address checkbox is checked
        if (useShippingAsBilling) {
          props.setBillingAddress(address)
        }
      })
    } else if (addressType === 'billing') {
      props.setBillingAddress(deselect ? {} : address)
    }
  }

  const handleClickUseAsBilling = e => {
    const incomingValue = e.target.checked
    setUseShippingAsBilling(incomingValue)

    if (incomingValue === false) {
      props.setBillingAddress({})
      return
    }

    const customerAddressPresent = !_.isEmpty(props.selectedCustomerAddress)
    const billingAddressPresent = !_.isEmpty(props.selectedBillingAddress)
    const addressesAreSame = props.selectedBillingAddress.id === props.selectedCustomerAddress.id

    if (incomingValue === true && customerAddressPresent && !addressesAreSame) {
      props.setBillingAddress(props.selectedCustomerAddress)
    }
  }

  const addressesAreEqual = (address1, address2) => {
    return address1.id === address2.id
  }
  const bothAddressesPresent = (address1, address2) => {
    return !!(address1.id && address2.id)
  }
  const addressesEqualAndPresent = (address1, address2) => {
    const addressesAreSame = addressesAreEqual(address1, address2)
    const addressesArePresent = bothAddressesPresent(address1, address2)

    return addressesAreSame && addressesArePresent
  }

  const showBillingAddress =
    addressType === 'delivery' && (props.youPay > 0 || (props.youPay === 0 && props.requireBillingAddressForFreeOrders))

  return (
    <>
      <Row>
        <Col xs="12" style={{ paddingBottom: '0px' }}>
          <AddressList
            addresses={addresses}
            addressType={addressType}
            handleSelectAddress={handleSelectAddress}
            selectedAddress={addressType === 'billing' ? props.selectedBillingAddress : props.selectedCustomerAddress}
            useShippingAsBilling={useShippingAsBilling}
          />
        </Col>
      </Row>

      {showBillingAddress ? (
        <FormGroup check className="float-right w-100 pb-3 pl-0 pr-3">
          <Label check className="float-right">
            <Input
              type="checkbox"
              onChange={handleClickUseAsBilling}
              checked={
                useShippingAsBilling ||
                addressesEqualAndPresent(props.selectedBillingAddress, props.selectedCustomerAddress)
              }
            />
            Use as billing address
          </Label>
        </FormGroup>
      ) : (
        <div className="float-right w-100 pb-3" />
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    requireBillingAddressForFreeOrders: state.currentLocation.require_billing_address_for_free_orders,
    selectedDeliveryAddress: state.checkout.selectedDeliveryAddress,
    managed_customer_addresses: state.managed_customer_addresses,
    customer_addresses: state.customer_addresses,
    selectedCustomerAddress: state.checkout.selectedCustomerAddress,
    selectedBillingAddress: state.checkout.selectedBillingAddress,
    youPay: state.checkout.youPay,
    portal: state.portal
  }
}

export default connect(mapStateToProps, {
  setCustomerAddress,
  setBillingAddress
})(CheckoutAddressPanel)
