import _ from 'lodash'
import brandit_rails from '../../api/brandit_rails'
// CHECKOUT PAGE FUNCTIONS
export const mapDispatchToProps = dispatch => {
  return {
    updateCreditCard: data => dispatch({ type: 'UPDATE_CREDIT_CARD', payload: data }),
    updateAddress: data => dispatch({ type: 'UPDATE_CHECKOUT_ADDRESS', payload: data })
  }
}

export const setCcData = data => async dispatch => dispatch({ type: 'SET_CC_DATA', payload: data })
export const clearShippingQuote = () => async dispatch => dispatch({ type: 'CLEAR_SHIPPING_QUOTE' })
export const setShippingLoading = bool => async dispatch => dispatch({ type: 'SET_SHIPPING_LOADING', payload: bool })
export const setTaxLoading = bool => async dispatch => dispatch({ type: 'SET_TAX_LOADING', payload: bool })
export const setErrors = data => async dispatch => dispatch({ type: 'SET_CHECKOUT_ERROR', payload: data })
export const setCustomerAddress = addressObj => async dispatch =>
  dispatch({ type: 'SET_CUSTOMER_ADDRESS', payload: addressObj })
export const setBillingAddress = addressObj => async dispatch =>
  dispatch({ type: 'SET_BILLING_ADDRESS', payload: addressObj })
export const setCreditsUsed = creditsUsedObject => async dispatch =>
  dispatch({ type: 'SET_CREDITS_USED', payload: creditsUsedObject })
export const setPaymentMethod = paymentMethod => async dispatch =>
  dispatch({ type: 'SET_PAYMENT_METHOD', payload: paymentMethod })
export const setAppliedCoupon = code => async dispatch => dispatch({ type: 'SET_APPLIED_COUPON', payload: code })
export const setPoNumber = input => async dispatch => dispatch({ type: 'SET_PO_NUMBER', payload: input })
export const setSubtotal = price => async dispatch => dispatch({ type: 'SET_SUBTOTAL', payload: price })
export const setCheckoutLoading = bool => async dispatch => dispatch({ type: 'SET_CHECKOUT_LOADING', payload: bool })
export const setManagedAddressesLoading = bool => async dispatch =>
  dispatch({ type: 'SET_MANAGED_ADDRESSES_LOADING', payload: bool })
export const setCustomerAddressesLoading = bool => async dispatch =>
  dispatch({ type: 'SET_CUSTOMER_ADDRESSES_LOADING', payload: bool })
export const setCheckoutErrorMessage = message => async dispatch =>
  dispatch({ type: 'SET_CHECKOUT_ERROR_MESSAGE', payload: message })
export const setNet30Confirmed = bool => async dispatch => dispatch({ type: 'SET_NET30_CONFIRMED', payload: bool })
export const setSetupCharge = price => async dispatch => dispatch({ type: 'SET_SETUP_CHARGE', payload: price })
export const setCustomCheckoutFee = price => async dispatch =>
  dispatch({ type: 'SET_CUSTOM_CHECKOUT_FEE', payload: price })
// Resets Checkout Reducer after order placed
export const checkoutOrderCleanup = location => async dispatch =>
  dispatch({ type: 'CHECKOUT_CLEANUP', payload: location })
export const setOrderProcessing = bool => async dispatch => dispatch({ type: 'SET_ORDER_PROCESSING', payload: bool })
export const setMaxQuantityLoading = bool => async dispatch => dispatch({ type: 'MAX_QUANTITY_LOADING', payload: bool })
export const setCustomerNotes = note => async dispatch => dispatch({ type: 'SET_CUSTOMER_NOTES', payload: note })
export const setMultiAddressSelection = (cartItemId, selections) => async dispatch =>
  dispatch({ type: 'SET_MULTI_ADDRESS_SELECTION', payload: { cartItemId, selections } })
export const clearMultiAddressSelection = cartItemId => async dispatch =>
  dispatch({ type: 'CLEAR_MULTI_ADDRESS_SELECTION', payload: cartItemId })
export const setCheckoutFormData = data => async dispatch => dispatch({ type: 'SET_CHECKOUT_FORM_DATA', payload: data })

// for each cart item, sum the resolved price
// e.g. coop price, tier price, regular price, etc
export const calculateCartSubtotal = cartItems => {
  return cartItems.reduce((total, cartItem) => resolvePrice(cartItem.priceData) + total, 0)
}

export const resolveUnitPrice = (priceData, considerDiscount = true) => {
  // default final price to item subtotal. change only if there are modifiers
  let result = priceData.unit_price
  const {
    tier_price_applied,
    tier_price_unit_price,
    sale_price_applied,
    sale_price_unit_price,
    configurable_additional_price_applied,
    template_additional_price_applied,
    extra_price_cents,
    adjusted_unit_price,
    template_total_template_additional_unit_price,
    configurable_additional_price_per_unit
  } = priceData

  if (tier_price_applied === true) {
    result = tier_price_unit_price
  }
  if (sale_price_applied === true) {
    result = sale_price_unit_price
  }

  // if a sale price AND tier price are present, use whichever price is lower
  if (tier_price_applied && sale_price_applied) {
    result = tier_price_unit_price < sale_price_unit_price ? tier_price_unit_price : sale_price_unit_price
  }

  // additional prices
  if (configurable_additional_price_applied === true) {
    result += configurable_additional_price_per_unit
  }
  if (template_additional_price_applied === true) {
    result += template_total_template_additional_unit_price
  }
  if (extra_price_cents) {
    result += extra_price_cents
  }

  // if discounts are present, optionally include discounted price
  if (adjusted_unit_price >= 0 && considerDiscount === true) {
    result = adjusted_unit_price
  }
  return result
}

// Takes priceData object and reduces to final price (before discounts)
export const resolvePrice = (priceData, includeDiscounts = false, includeCredits = false, isBundle = false) => {
  // optionally use discounted price
  if (
    includeDiscounts === true &&
    ((priceData.discounts && !_.isEmpty(priceData.discounts)) || priceData.adjusted_subtotal >= 0)
  ) {
    return priceData.adjusted_subtotal
  }

  // default final price to item subtotal. change only if there are modifiers
  let result = priceData.subtotal

  if (priceData.tier_price_applied === true) {
    result = priceData.tier_price_subtotal
  }
  if (priceData.sale_price_applied === true) {
    // if there is both a tier price and a sale price, use whichever is lower
    if (priceData.tier_price_applied === true) {
      const specialPriceIsLowerThanTierPrice = priceData.sale_price_subtotal < priceData.tier_price_subtotal
      if (specialPriceIsLowerThanTierPrice) {
        result = priceData.sale_price_subtotal
      }
    } else {
      result = priceData.sale_price_subtotal
    }
  }
  if (priceData.configurable_additional_price_applied === true) {
    result += priceData.configurable_additional_price_subtotal
  }
  if (priceData.template_additional_price_applied === true) {
    result += priceData.template_additional_price_subtotal
  }
  if (priceData.extra_price_cents) {
    result += priceData.extra_price_cents_subtotal
  }

  // If bundle, need setup charge seperately to differentiate prices later
  if (isBundle) {
    result = Object.values(result).length > 0 ? { ...result } : { result, setupCharge: 0 }
    if (priceData.setup_charge) {
      result = {
        result: (result.result += priceData.setup_charge),
        setupCharge: (result.setupCharge += priceData.setup_charge)
      }
    }
  } else {
    if (priceData.setup_charge) {
      result += priceData.setup_charge
    }
  }
  return result
}

export const calculateGrandTotal = (
  cartItemsSubtotal,
  totalTax = 0,
  totalShipping = 0,
  totalCreditsUsed = 0,
  subtotalReduction = 0
) => {
  return cartItemsSubtotal + totalTax + totalShipping - totalCreditsUsed - subtotalReduction
}

export const calculateCartSetupCharge = cartItems => {
  return cartItems.reduce((total, cartItem) => cartItem.priceData.setup_charge + total, 0)
}

export const validateCheckoutForm = checkoutForm => {
  return checkoutForm.every(field => {
    switch (field.field_type) {
      case 'checkbox':
        return field.options.filter(option => option.is_required).every(option => option.checked)
      case 'radio':
        return !field.options[0].is_required || field.options.some(option => option.checked)
      case 'customer_notes':
        return !field.options[0].is_required || (field.options[0].value && field.options[0].value.length > 0)
      default:
        return true
    }
  })
}

export const fetchGrandTotal =
  (subtotal, taxQuote, shippingDue, creditsUsed, promotions, coupons) => async dispatch => {
    // const params = {
    // }
    // params required to calculate grand total:
    // cartItemsSubtotal: state.subtotal,
    // - totalTax: state.taxQuote,
    // totalShipping: shippingDue < 0 ? 0 : shippingDue,
    // creditsUsed: state.creditsUsed,
    // - subtotalDiscount: state.subtotalReduction + state.productReduction,

    await brandit_rails
      .post('/checkout', {
        subtotal,
        taxQuote,
        shippingDue,
        creditsUsed,
        promotions,
        coupons
      })
      .then(response => {
        dispatch({ type: 'SET_GRAND_TOTAL', payload: response.data })
      })
  }
