import _ from 'lodash'
import { useEffect, useState } from 'react'
import { Lock } from 'react-feather'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { withRouter } from 'react-router'
import Sticky from 'react-sticky-el'
import { toast } from 'react-toastify'
import { Button, Col, Spinner, Row } from 'reactstrap'
import { compose } from 'redux'
import { dispatchSweetAlert } from '../../actions'
import { cartItemsRequireCheckForApproval, returnCartItemsRequireApprovalError } from '../Cart/cartFunctions'
import ClearCartButton from '../Cart/ClearCartButton'
import {
  setSubtotal,
  calculateCartSubtotal,
  setCheckoutLoading,
  calculateCartSetupCharge,
  setSetupCharge,
  setErrors,
  setCustomCheckoutFee,
  validateCheckoutForm,
  fetchGrandTotal
} from '../Checkout/checkoutFunctions'
import dollar_price from '../general/DollarPrice'
import CheckoutConfirm from './CheckoutConfirm'
import useRecalculateGrandTotal from './useRecalculateGrandTotal'

const OrderSummary = props => {
  const [canProceed, setCanProceed] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState('')
  const [isZoomedIn, setIsZoomedIn] = useState(false)
  const [checkoutFormIsValid, setCheckoutFormIsValid] = useState(false)
  const [checkoutHasNoErrors, setCheckoutHasNoErrors] = useState(true)

  useRecalculateGrandTotal(props)

  // watch checkoutErrors and set message appropriately
  useEffect(() => {
    const checkoutErrorsExist = Object.values(props.errors).some(
      v => v === true || (typeof v === 'object' && !_.isEmpty(v))
    )

    if (checkoutErrorsExist) {
      setCheckoutHasNoErrors(false)
      if (Object.keys(props.errors).some(key => key.includes('multiAddress') && props.errors[key] === true)) {
        setCheckoutErrorMessage('Please see multi-address selections.')
      } else if (
        Object.keys(props.errors).some(key => key.includes('requireApprovalRule') && props.errors[key] === true)
      ) {
        setCheckoutErrorMessage('Cart cannot contains items that both require approval and do not require approval.')
      } else {
        setCheckoutErrorMessage('')
      }
    } else {
      setCheckoutErrorMessage('')
      setCheckoutHasNoErrors(true)
    }
  }, [props.errors])

  // check if user can proceed with checkout
  useEffect(() => {
    // debugger
    // each step may have to check ig payment method is net-30
    // if payment is required and no billing address
    const billingAddressValid =
      !(props.youPay > 0 && _.isEmpty(props.checkout.selectedBillingAddress)) ||
      (props.checkout.paymentMethod === 'net30' && props.checkout.net30Confirmed)

    // if using CC and any fields are blank, disable place order btn

    let ccValid =
      props.checkout.paymentMethod === 'credit_card' &&
      !(
        props.checkout.ccData.card_number === '' ||
        props.checkout.ccData.card_cvc === '' ||
        props.checkout.ccData.card_expiry === '' ||
        props.checkout.ccData.card_name === ''
      )

    if (props.checkout.youPay === 0 || (props.checkout.paymentMethod === 'net30' && props.checkout.net30Confirmed)) {
      ccValid = true
    }

    // if customer address is empty - unless all cart items are digital
    const customerAddressValid =
      !_.isEmpty(props.selectedCustomerAddress) ||
      props.cartItems.every(cartItem => cartItem.shippable_type === 'Digital Delivery')

    // if billing address is empty and there is a balance due and not net30

    // if there are any checkout errors (props.checkout.errors)
    const checkoutErrorsExist = Object.values(props.errors).some(
      v => v === true || (typeof v === 'object' && !_.isEmpty(v))
    )

    // if there are any legacy errors (only shipping)
    let legacyErrorsExist = false
    if (props.reduxErrors['shipping_errors']) {
      legacyErrorsExist = true
    }

    let proceedToCheckoutAllowed =
      customerAddressValid &&
      billingAddressValid &&
      ccValid &&
      !checkoutErrorsExist &&
      !legacyErrorsExist &&
      checkoutFormIsValid &&
      checkoutHasNoErrors

    if (
      props.customerGroup.hide_credit_card &&
      !props.customerGroup.net_30_payments &&
      props.checkout.youPay > 0 &&
      !props.checkout.creditsUsed.lmsPoints &&
      !props.checkout.accountCredits &&
      !props.checkout.giftCard &&
      !props.checkout.coopCredits &&
      !props.checkout.net30Confirmed
    ) {
      proceedToCheckoutAllowed = false
    }
    if (
      props.customerGroup.hide_credit_card &&
      props.customerGroup.net_30_payments &&
      props.checkout.youPay > 0 &&
      !props.checkout.net30Confirmed
    ) {
      proceedToCheckoutAllowed = false
    }

    setCanProceed(proceedToCheckoutAllowed)
  }, [
    props.checkout.ccData,
    props.selectedCustomerAddress,
    props.selectedBillingAddress,
    props.checkout.creditsUsed,
    props.checkout.youPay,
    props.errors,
    props.reduxErrors,
    props.checkout.net30Confirmed,
    checkoutFormIsValid,
    checkoutHasNoErrors
  ])

  // recalculate subtotal/setup charge if cartItems changes
  useEffect(() => {
    props.setSubtotal(calculateCartSubtotal(props.cartItems))
    props.setSetupCharge(calculateCartSetupCharge(props.cartItems))
  }, [props.cartItems])

  // Set custom checkout fee from location
  useEffect(() => {
    if (props.currentLocation) {
      props.setCustomCheckoutFee(props.currentLocation.custom_checkout_fee)
    }
  }, [props.currentLocation])

  // Check if cart items contain both requires approval and does not require approval
  useEffect(() => {
    if (
      props.cartItems &&
      props.cartItems.length > 0 &&
      cartItemsRequireCheckForApproval(null, props.cartItems, props.currentLocation)
    ) {
      props.returnCartItemsRequireApprovalError(props.cartItems, null)
      props.setErrors({ requireApprovalRule: true })
    } else {
      props.setErrors({ requireApprovalRule: false })
    }
  }, [props.cartItems])

  const detectZoom = () => {
    const zoomLevel = Math.round(window.devicePixelRatio * 100)
    if (zoomLevel > 100) {
      setIsZoomedIn(true)
    } else {
      setIsZoomedIn(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', detectZoom)
    return () => window.removeEventListener('resize', detectZoom)
  }, [detectZoom])

  // validate checkout form
  useEffect(() => {
    const checkoutFormValid = validateCheckoutForm(props.checkoutFormData)
    setCheckoutFormIsValid(checkoutFormValid)
  }, [props.checkoutFormData])

  const proceedToCheckout = () => {
    const user_id = props.currentUser.id

    // if user is not logged in, show Toast error & redirect
    if (!user_id) {
      toast.info('You need to Sign In to proceed to checkout!', {
        position: toast.POSITION.TOP_CENTER,
        type: toast.TYPE.SUCCESS,
        className: 'address-toast',
        autoClose: 3000
      })
      props.history.push(`/${props.portal.custom_login_url}`)
    } else if (props.errors.stock) {
      props.dispatchSweetAlert({
        type: 'error',
        alertMessage: 'Some items are out of stock! Please remove the highlighted items from your basket to continue.',
        showCancel: false,
        confirmBtnCssClass: 'mf-primary-btn alert-width-btn'
      })
    } else {
      // check stock. if stock check is ok, proceed to checkout, else set state errors
      props.history.push('/check-out')
    }
  }

  // check for errors and show order confirmation modal
  const handlePlaceOrder = () => {
    if (props.checkout.youPay > 0 && props.checkout.paymentMethod === 'credit_card') {
      // check CC details here
      const ccData = props.checkout.ccData
      const mm = ccData.card_expiry.substring(0, 2)
      const yy = ccData.card_expiry.substring(2)
      const now = new Date()
      const thisCentury = `${now.getFullYear()}`.substring(0, 2)
      const monthIndex = parseInt(mm) - 1
      const expiryDate = new Date(`${thisCentury}${yy}`, monthIndex)
      // remove spaces from card number
      ccData.card_number = ccData.card_number.replace(/\s/g, '')
      const ccError =
        expiryDate < now ||
        ccData.card_number.length < 12 ||
        ccData.card_number.length > 19 ||
        !ccData.card_name ||
        !ccData.card_cvc ||
        !ccData.card_expiry
      if (ccError) {
        props.dispatchSweetAlert({
          type: 'error',
          alertMessage: 'Please check your card details.',
          showCancel: false,
          confirmBtnCssClass: 'mf-primary-btn alert-width-btn mr-0'
        })
        return
      }
    }
    setShowConfirmationModal(true)
  }

  const displayCreditsUsed = (label, amount) => {
    return (
      <Col lg="12" className="flex-space-between m-0 green-text">
        <h6>{label}:</h6>
        <h6>
          {props.checkout.checkoutLoading ||
          (props.checkout.shippingLoading && !_.isEmpty(props.checkout.selectedCustomerAddress)) ||
          props.checkout.taxLoading ? (
            <Spinner />
          ) : (
            <>-{dollar_price(amount)}</>
          )}
        </h6>
      </Col>
    )
  }

  const displayPriceReduction = (discountType, amount, showHorizontalRule = false) => {
    return (
      <>
        <Col lg="12" className="flex-space-between m-0 green-text">
          <h6>{discountType} Discount:</h6>
          <h6>-{dollar_price(amount)}</h6>
        </Col>
        {showHorizontalRule ? <hr className="w-100" /> : null}
      </>
    )
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  // CALCULATES HEIGHT OF SCROLL DOWN MENU AND ADDS MARGIN ON STICKY SIDEBAR
  const fixedHeaderElement = document.querySelector('.iron-fixed-header')
  const menuHeight = fixedHeaderElement !== null ? fixedHeaderElement.offsetHeight : '100px'
  const screenHeight = window.screen.availHeight
  const responseHeight = screenHeight !== null && screenHeight <= 770 ? true : false

  const allItemsDigitalDelivery = props.cartItems.every(cartItem => cartItem.shippable_type === 'Digital Delivery')
  const shippingAddressRequired = !allItemsDigitalDelivery

  const apisLoading =
    props.checkout.checkoutLoading ||
    props.checkout.shippingLoading ||
    props.checkout.taxLoading ||
    props.checkout.cart_loading

  const errorsOnScreen = Object.values(props.errorsOnScreen).some(v => v !== null)

  const placeYourOrderDisabled =
    errorsOnScreen ||
    !canProceed ||
    apisLoading ||
    (props.checkout.paymentMethod === 'net30' &&
      props.checkout.youPay > 0 &&
      _.isEmpty(props.selectedBillingAddress)) ||
    (props.checkout.youPay === 0 && props.requireBillingAddressForFreeOrders && _.isEmpty(props.selectedBillingAddress))
  const totalCostWithoutPaymentReduction =
    props.checkout.subtotal + props.checkout.taxQuote + props.checkout.shippingQuote

  return (
    <>
      <Sticky
        topOffset={0}
        stickyStyle={{
          top: menuHeight >= 90 ? '160px' : '100px',
          zIndex: '2'
        }}
        // onFixedToggle= {}
        disabled={isTabletOrMobile || responseHeight || isZoomedIn}
        className="m125-auto order-summary-custom-css"
      >
        <div className="m-0 box-shadow white-background">
          <div className="ckout-header">
            <h5 className="m-0">Order Summary</h5>
          </div>
          <Col lg="12" className="p-3">
            <hr className={props.currentLocation.show_price ? 'w-100' : 'd-none'} />

            {/* Set Up Charge */}
            <Col lg="12" className={props.currentLocation.show_price ? 'flex-space-between m-0 mb-1' : 'd-none'}>
              {props.setupCharge > 0 ? (
                props.isCheckout ? (
                  <>
                    <h6>Setup Charge:</h6>
                    <h6>{dollar_price(props.setupCharge)}</h6>
                  </>
                ) : (
                  <>
                    <h5>Setup Charge:</h5>
                    <h5>{dollar_price(props.setupCharge)}</h5>
                  </>
                )
              ) : null}
            </Col>

            {/* Sub Total */}
            <Col lg="12" className={props.currentLocation.show_price ? 'flex-space-between m-0' : 'd-none'}>
              {props.isCheckout ? (
                <>
                  <h6>Subtotal:</h6>
                  <h6>{dollar_price(props.subtotal)}</h6>
                </>
              ) : (
                <>
                  <h5>Subtotal:</h5>
                  <h5>{dollar_price(props.subtotal)}</h5>
                </>
              )}
            </Col>

            {/* Checkout Form */}
            {!checkoutFormIsValid && props.isCheckout ? (
              <>
                <hr className="w-100" />
                <Col lg="12" className="flex-space-between m-0">
                  <h6 className="red-text">Please Complete All Required Fields In Checkout Information Form</h6>
                </Col>
              </>
            ) : null}

            {/* Shipping */}
            {props.currentLocation.show_price && props.isCheckout ? (
              <>
                <hr className="w-100" />
                <Col lg="12" className="flex-space-between m-0">
                  {_.isEmpty(props.selectedCustomerAddress) && shippingAddressRequired ? (
                    <h6 className="red-text">Please select a shipping address</h6>
                  ) : (
                    <>
                      <h6>Shipping:</h6>
                      {props.shippingLoading === true ? <Spinner /> : <h6>{dollar_price(props.shippingQuote)}</h6>}
                    </>
                  )}
                </Col>
              </>
            ) : null}

            {props.checkout.shippingReduction || props.checkout.subtotalReduction || props.checkout.productReduction ? (
              <hr />
            ) : null}

            {/* Shipping Reduction from promotion */}
            {props.checkout.shippingReduction
              ? displayPriceReduction('Shipping', props.checkout.shippingReduction)
              : null}

            {/* Subtotal Reduction from promotion */}
            {props.checkout.subtotalReduction
              ? displayPriceReduction('Subtotal', props.checkout.subtotalReduction)
              : null}

            {/* Product Reduction from promotion */}
            {props.checkout.productReduction
              ? displayPriceReduction('Product', props.checkout.productReduction, true)
              : null}

            {/* Tax */}
            {props.currentLocation.show_price && props.isCheckout && !_.isEmpty(props.selectedCustomerAddress) ? (
              <>
                <Col lg="12" className="flex-space-between m-0">
                  <h6>Sales Tax:</h6>
                  {props.checkout.taxLoading || props.checkout.checkoutLoading || props.checkout.shippingLoading ? (
                    <Spinner />
                  ) : (
                    <h6>{dollar_price(props.taxQuote)}</h6>
                  )}
                </Col>
              </>
            ) : null}

            {/* Extra Checkout Fee */}
            {props.currentLocation.show_price && props.isCheckout && props.customCheckoutFee > 0 ? (
              <>
                <Col lg="12" className="flex-space-between m-0">
                  <h6>{props.currentLocation.custom_checkout_fee_alias}:</h6>
                  <h6>{dollar_price(props.customCheckoutFee)}</h6>
                </Col>
              </>
            ) : null}

            {/* Error For Not Enough LMS Points */}
            {props.portal.use_lms_points &&
            totalCostWithoutPaymentReduction > props.userCredits.lms_points.remaining ? (
              <Col lg="12" className="flex-space-between m-0">
                <hr className="w-100" />
                <h6 className="red-text">Not Enough Points To Checkout</h6>
              </Col>
            ) : null}

            {/* For Checkout Only */}
            {/* Co-Op & You Pay */}
            {props.isCheckout ? (
              <>
                {/* Credits Used */}
                {props.creditsUsed.coopCredits || props.creditsUsed.accountCredits || props.creditsUsed.giftCard ? (
                  <hr />
                ) : null}

                {/* Coop Reduction */}
                {props.creditsUsed.coopCredits ? (
                  <Col lg="12" className="flex-space-between m-0 green-text">
                    <h6>{localStorage.getItem('coop_def')}:</h6>
                    <h6>-{dollar_price(props.creditsUsed.coopCredits)}</h6>
                  </Col>
                ) : null}

                {props.checkout.creditsUsed.accountCredits
                  ? displayCreditsUsed(localStorage.getItem('account_credit_def'), props.creditsUsed.accountCredits)
                  : null}

                {props.checkout.creditsUsed.giftCard
                  ? displayCreditsUsed('Gift Card', props.creditsUsed.giftCard)
                  : null}

                {props.checkout.creditsUsed.lmsPoints
                  ? displayCreditsUsed('Points', props.creditsUsed.lmsPoints)
                  : null}

                {props.currentLocation.show_price ? (
                  <Col lg="12" className="flex-space-between m-0 mt-3">
                    <h5>You Pay:</h5>
                    <h5>
                      {props.checkout.checkoutLoading ||
                      (props.checkout.shippingLoading && !_.isEmpty(props.checkout.selectedCustomerAddress)) ||
                      props.checkout.taxLoading ? (
                        <Spinner />
                      ) : (
                        <>{dollar_price(props.youPay)}</>
                      )}
                    </h5>
                  </Col>
                ) : null}

                {/* Place Order Button (opens confirmation alert box) */}
                <Col sm="12">
                  <Button
                    className="w-100 mf-secure-co-btn mt-3 mb-2 ml-0 mr-0 ckout-btn-custom-css"
                    onClick={handlePlaceOrder}
                    disabled={placeYourOrderDisabled}
                  >
                    <Lock />
                    &nbsp;&nbsp;Place Your Order
                  </Button>
                  {checkoutErrorMessage ? <p style={{ color: 'red' }}>{checkoutErrorMessage}</p> : null}
                </Col>
              </>
            ) : null}

            {!props.isCheckout && (
              <>
                <Button
                  className="w-100 mf-secure-co-btn mt-2 mb-2 ckout-btn-custom-css"
                  onClick={proceedToCheckout}
                  disabled={
                    !!props.errorMessage ||
                    props.checkout.maxQuantityLoading ||
                    props.checkout.checkoutLoading ||
                    cartItemsRequireCheckForApproval(null, props.cartItems, props.currentLocation) ||
                    props.checkout.cart_loading
                  }
                >
                  <Lock />
                  &nbsp;&nbsp;Proceed to Checkout
                </Button>
                <br />
                <sub>{props.errorMessage}</sub>
                <ClearCartButton />
              </>
            )}
          </Col>
        </div>
      </Sticky>

      {/* Order Confirmation Modal */}
      <CheckoutConfirm
        canProceed={canProceed}
        show={showConfirmationModal}
        hideModal={() => setShowConfirmationModal(false)}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    cartItems: state.cartItems,
    grandTotal: state.checkout.grandTotal,
    subtotal: state.checkout.subtotal,
    portal: state.portal,
    errorMessage: state.checkout.errorMessage,
    taxQuote: state.checkout.taxQuote,
    shippingQuote: state.checkout.shippingQuote,
    errorsOnScreen: state.errors,
    errors: state.checkout.errors,
    creditsUsed: state.checkout.creditsUsed,
    youPay: state.checkout.youPay,
    checkout: state.checkout,
    selectedCustomerAddress: state.checkout.selectedCustomerAddress,
    selectedBillingAddress: state.checkout.selectedBillingAddress,
    reduxErrors: state.errors,
    shippingLoading: state.checkout.shippingLoading,
    currentLocation: state.currentLocation,
    location: state.locations[0],
    userCredits: state.userCredits,
    setupCharge: state.checkout.setupCharge,
    currentUser: state.currentUser,
    customCheckoutFee: state.checkout.customCheckoutFee,
    checkoutForm: state.checkoutForm,
    checkoutFormData: state.checkout.checkoutFormData,
    customerGroup: state.customerGroup,
    requireBillingAddressForFreeOrders: state.currentLocation.require_billing_address_for_free_orders
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    setSubtotal,
    dispatchSweetAlert,
    setCheckoutLoading,
    setSetupCharge,
    returnCartItemsRequireApprovalError,
    setErrors,
    setCustomCheckoutFee,
    fetchGrandTotal
  })
)(OrderSummary)
