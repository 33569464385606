import _ from 'lodash'
import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { withRouter } from 'react-router'
import { Input, Button, Col, InputGroup } from 'reactstrap'
import { compose } from 'redux'
import { fetchCoupon, fetchDiscounts, updateCartItems } from '../../actions'
import { setAppliedCoupon, resolveUnitPrice } from './checkoutFunctions'

const PromotionsPanel = props => {
  const [loading, setLoading] = useState(false)
  const [couponCode, setCouponCode] = useState('')

  const promoApplied = props.appliedPromotions.length > 0

  // if shipping quote changes, re-fetch discounts if we have a promo that discounts shipping
  useEffect(() => {
    // debugger
    if (props.cartItems.length > 0) {
      fetchDiscounts('discount')
      fetchDiscounts('coupon')
    }
  }, [props.shippingQuote])

  // when applied promotions change, we must update cartItems' priceData with any applicable discounts
  // and also apply any cart-wide discounts for tax, shipping
  useEffect(() => {
    const appliedPromotion = props.appliedPromotions[0]
    // if there are any promotions
    if (appliedPromotion && appliedPromotion.updated_cart) {
      // todo: handle multiple promotions
      // update cart items with discounts from promotion
      const newCartItems = addDiscountsToCartItems(props.cartItems, appliedPromotion.updated_cart)
      props.updateCartItems(newCartItems)
    }
  }, [props.appliedPromotions])

  const addDiscountsToCartItems = (cartItems, updatedCart) => {
    const cartItemsWithDiscounts = _.cloneDeep(cartItems)
    return cartItemsWithDiscounts.map(cartItem => {
      // search promotions to see if cartItem has a discount
      let thisCartItemsDiscounts = {}
      const discountsEntryForThisCartItem = updatedCart.find(el => el.cart_item_id === cartItem.cart_item_id)

      let isProductDiscount = false

      // if there is a discount for this cart item
      if (discountsEntryForThisCartItem && discountsEntryForThisCartItem.discount) {
        const thisItemPromoData = discountsEntryForThisCartItem.items[0]

        // so we know if it's a product discount, as it will only apply to a single item
        if (thisItemPromoData.product_discount >= 0) {
          isProductDiscount = true
        }

        thisCartItemsDiscounts = {
          ...thisItemPromoData,
          discount: discountsEntryForThisCartItem.discount
        }
      }

      // will need to adjust coop as well after discounting item
      const coopPercentage = props.currentLocation.coop_percentage_100
        ? props.currentLocation.coop_percentage_100
        : props.portal.coop_percentage_100

      let newSubtotal
      let newUnitPrice
      // if discount is a product discount, the adjusted subtotal should only be reduced by a single item
      // additionally, the unit price should be adjusted to reflect the discount if qty > 1
      if (isProductDiscount === true) {
        newSubtotal = cartItem.priceData.subtotal - thisCartItemsDiscounts.discount
        newUnitPrice = newSubtotal / cartItem.quantity // if product discount, we need to divide by qty
      } else {
        newSubtotal = thisCartItemsDiscounts.discounted_price * cartItem.quantity
        newUnitPrice = thisCartItemsDiscounts.discounted_price
      }

      // if breakable bundle, we need to add the discounts to the child products
      if (cartItem.flags.bundle_type === 'Breakable') {
        // add adjusted_unit_price to each child product
        // to do this we need to calculate the ratio of the parent product's discount to the original price
        // (this is done regardless of the discount being a percentage discount)
        const percentageToDiscountChildProducts = newUnitPrice / cartItem.priceData.unit_price

        cartItem.bundle.products = cartItem.bundle.products.map(bundleProduct => {
          // Todo: may need to adjust coop prices here as well?

          const bundleProductUnitPrice = resolveUnitPrice(bundleProduct.priceData, false)

          bundleProduct.priceData.adjusted_unit_price = bundleProductUnitPrice * percentageToDiscountChildProducts
          bundleProduct.priceData.adjusted_subtotal =
            bundleProduct.priceData.adjusted_unit_price * bundleProduct.product_quantity
          return bundleProduct
        })
      }

      return {
        ...cartItem,
        priceData: {
          ...cartItem.priceData,
          adjusted_unit_price: newUnitPrice,
          adjusted_subtotal: newSubtotal,
          adjusted_coop_deduction_cents: newUnitPrice * (coopPercentage / 100),
          adjusted_coop_deduction_subtotal: newSubtotal * (coopPercentage / 100),
          discounts: thisCartItemsDiscounts
        }
      }
    })
  }

  // Build JSON for Tax API call from cart items
  const explodeBasket = cartItems => {
    return cartItems.map((cartItem, index) => {
      const itemPrice = resolveUnitPrice(cartItem.priceData, false)
      const taxablePrice = cartItem.enable_tax === false ? 0 : cartItem.priceData.subtotal

      return {
        id: index + 1,
        quantity: cartItem.quantity,
        product_id: cartItem.product_id,
        sku: cartItem.sku,
        base_price: cartItem.price,
        name: cartItem.name,
        enable_tax: cartItem.enable_tax,
        cart_item_id: cartItem.cart_item_id,
        items: [
          {
            id: index + 1,
            item_price: itemPrice,
            discounted_price: itemPrice,
            taxable_price: taxablePrice,
            quantity: cartItem.quantity
          }
        ]
      }
    })
  }

  const fetchDiscounts = promoType => {
    const promotionsArray = props.appliedPromotions

    // see if any discounts are already applied - return if so
    const numberOfDiscountsApplied = _.filter(props.appliedPromotions, promo => promo.type === 'discount').length
    const numberOfCouponsApplied = _.filter(promotionsArray, promo => promo.type === 'coupon').length
    const existingCouponCode =
      numberOfCouponsApplied > 0 ? promotionsArray.find(promo => promo.type === 'coupon').code_used : null

    const cartForForm = explodeBasket(props.cartItems)

    if (promoType === 'discount') {
      const formDataDiscount = new FormData()
      formDataDiscount.append('applied_count', numberOfDiscountsApplied)
      formDataDiscount.append('subtotal', props.subtotal)
      formDataDiscount.append('shipping_cost', props.shippingQuote)
      formDataDiscount.append('cart_items', JSON.stringify(cartForForm))
      setLoading(true)

      props.fetchDiscounts(formDataDiscount).then(() => {
        setLoading(false)
      })
    } else {
      if (!existingCouponCode && !couponCode) {
        return
      }
      const formDataCoupon = new FormData()
      formDataCoupon.append('applied_count', numberOfDiscountsApplied)
      formDataCoupon.append('subtotal', props.subtotal)
      formDataCoupon.append('shipping_cost', props.shippingQuote)
      formDataCoupon.append('cart_items', JSON.stringify(cartForForm))
      formDataCoupon.append('coupon_code', existingCouponCode ? existingCouponCode : couponCode)
      setLoading(true)

      props.fetchCoupon(formDataCoupon).then(() => {
        setLoading(false)
        props.setAppliedCoupon(existingCouponCode ? existingCouponCode : couponCode)
      })
    }
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 752px)' })

  return (
    <div
      className={`promo-custom-css ${
        isTabletOrMobile ? 'm125-auto box-shadow white-background' : 'm-0 box-shadow white-background'
      }`}
    >
      <div className="ckout-header">
        <h5 className="m-0"> Promo Code</h5>
      </div>
      <Col className="p-3 mb-4">
        {props.appliedPromotions.map((promo, i) => {
          return (
            <div key={i} className="alert-success p-2 mb-3" role="alert">
              <p className="m-0">
                {promo.name}
                {promo.type === 'coupon' ? `: [${promo.code_used}]` : ''}
              </p>
            </div>
          )
        })}

        <InputGroup>
          <Input
            required
            label="Coupon Code"
            margin="normal"
            variant="outlined"
            name="coupon_code"
            id="coupon_code"
            onChange={e => setCouponCode(e.target.value)}
            className="coupon-mobile"
          />
          <Button
            onClick={() => fetchDiscounts('coupon')}
            disabled={loading || promoApplied}
            type="submit"
            className="mf-outline-btn pt-0 pb-0 pl-10 pr-10 lh-1"
          >
            Submit
          </Button>
        </InputGroup>
        <sub>Coupon codes are case-sensitive.</sub>
        {props.errors.code_errors ? (
          <>
            <br />
            <sub style={{ color: 'red' }}>{props.errors.code_errors.message}</sub>
          </>
        ) : null}
      </Col>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    selectedCustomerAddress: state.checkout.selectedCustomerAddress,
    couponApplied: state.checkout.couponApplied,
    cartItems: state.cartItems,
    appliedPromotions: state.checkout.appliedPromotions,
    subtotal: state.checkout.subtotal,
    portal: state.portal,
    shippingQuote: state.checkout.shippingQuote,
    shippingReduction: state.checkout.shippingReduction,
    subtotalReduction: state.checkout.subtotalReduction,
    errors: state.errors,
    checkout: state.checkout,
    currentLocation: state.currentLocation,
    currentUser: state.currentUser
  }
}

export default compose(
  connect(mapStateToProps, {
    fetchCoupon,
    fetchDiscounts,
    updateCartItems,
    setAppliedCoupon
  }),
  withRouter
)(PromotionsPanel)
