import _ from 'lodash'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import Sticky from 'react-sticky-el'
import { Container, Row, Col } from 'reactstrap'
import {
  fetchTax,
  fetchShippingQuote,
  createToken,
  clearPromotions,
  fetchCartItems,
  fetchUserCredit,
  fetchUser
} from '../../actions'
import { cartItemsRequireCheckForApproval, returnCartItemsRequireApprovalError } from '../Cart/cartFunctions'
import CartItemsList from '../Cart/CartItemsList'
import LoadingSpinner from '../general/LoadingSpinner'
import CheckoutAddressSelection from './CheckoutAddressSelection'
import CheckoutCardPanel from './CheckoutCardPanel'
import CheckoutCreditBalances from './CheckoutCreditBalances'
import CheckoutErrorsPanel from './CheckoutErrorsPanel'
import {
  setSubtotal,
  setErrors,
  setShippingLoading,
  checkoutOrderCleanup,
  setCheckoutLoading,
  setTaxLoading,
  resolveUnitPrice
} from './checkoutFunctions'
import CheckoutInformation from './CheckoutInformation'
import OrderSummary from './CheckoutOrderSummary'
import PromotionsPanel from './CheckoutPromotionsPanel'

const CheckoutPage = props => {
  useEffect(() => {
    props.setCheckoutLoading(true).then(() => {
      props.checkoutOrderCleanup(props.currentLocation).then(() => {
        props.clearPromotions().then(() => {
          const locationId = props.currentLocation.id
          const userId = props.currentUser.id

          window.scrollTo(0, 0)
          props.createToken(locationId, userId).then(() => {
            props.fetchUser()
            props.fetchUserCredit()
            props.fetchCartItems(userId, props.portal, props.currentLocation, props.userCredits.coop_credit.remaining)
          })
        })
      })
    })
  }, [props.currentLocation.id, props.currentUser.id])

  // if there are any legacy errors present, i.e. shipping, set that error in checkoutReducer
  useEffect(() => {
    const legacyShippingErrorExists =
      props.errors && props.errors.shipping_errors && props.errors.shipping_errors.message
    if (legacyShippingErrorExists && !props.checkoutErrors.shipping) {
      props.setErrors({ shipping: true })
    } else if (!legacyShippingErrorExists && props.checkoutErrors.shipping) {
      props.setErrors({ shipping: false })
    }
  }, [props.errors])

  // when any price totals change that affect tax, re-fetch tax quote
  useEffect(() => {
    const multiAddressShippingEnabled = props.portal.multiple_address_shipping

    // For multi-address shipping, make sure each cart item has a selection
    const eachItemHasSelection = multiAddressShippingEnabled
      ? props.cartItems.every(cartItem =>
          props.checkout.multiAddressSelection.map(s => s.cartItemId).includes(cartItem.cart_item_id)
        )
      : true // for single-address shipping, we don't need this condition

    // If there's a selected customer address, a positive subtotal, and the appropriate shipping settings, fetch tax
    if (
      !_.isEmpty(props.selectedCustomerAddress) &&
      (multiAddressShippingEnabled ? eachItemHasSelection : !multiAddressShippingEnabled)
    ) {
      fetchTaxQuote()
    }
  }, [
    props.checkout.multiAddressSelection,
    props.selectedCustomerAddress,
    props.subtotal,
    props.checkout.shippingQuote,
    props.checkout.subtotalReduction,
    props.checkout.shippingReduction,
    props.checkout.productReduction
  ])

  // when delivery address is selected, fetch shipping quote
  useEffect(() => {
    // call different API if smart address?
    if (!_.isEmpty(props.selectedCustomerAddress) && props.currentLocation.id) {
      props
        .fetchShippingQuote(props.selectedCustomerAddress, props.cartItems, props.checkout.multiAddressSelection)
        .then(resp => {
          props.setShippingLoading(false)
          props.setErrors({ shipping: false })
        })
        .catch(err => {
          props.setShippingLoading(false)
          props.setErrors({ shipping: true })
        })
    }
  }, [props.selectedCustomerAddress, props.checkout.multiAddressSelection, props.currentLocation.id])

  // Check if cart items contain both requires approval and does not require approval
  useEffect(() => {
    if (
      props.cartItems &&
      props.cartItems.length > 0 &&
      cartItemsRequireCheckForApproval(null, props.cartItems, props.currentLocation)
    ) {
      props.returnCartItemsRequireApprovalError(props.cartItems, null)
    }
  }, [props.cartItems])

  // Detect zoom level and set state
  const detectZoom = () => {
    const zoomLevel = Math.round(window.devicePixelRatio * 100)
    if (zoomLevel > 100) {
      setIsZoomedIn(true)
    } else {
      setIsZoomedIn(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', detectZoom)
    return () => window.removeEventListener('resize', detectZoom)
  }, [detectZoom])

  const addTaxablePriceToCartItems = cartItems => {
    const incomingCartItems = _.cloneDeep(cartItems)

    return incomingCartItems.map(cartItem => {
      // taxable price is unit price minus discounts
      // pass true to second arg to include any discount
      const cartItemUnitPriceCents = resolveUnitPrice(cartItem.priceData, true)
      return {
        name: cartItem.name,
        product_id: cartItem.product_id,
        quantity: cartItem.quantity,
        taxable_price: cartItemUnitPriceCents,
        cart_item_id: cartItem.cart_item_id
      }
    })
  }

  const fetchTaxQuote = () => {
    props.setTaxLoading(true)

    const formData = new FormData()
    const customer_address = props.checkout.selectedCustomerAddress

    const cartItemsWithTaxablePrice = addTaxablePriceToCartItems(props.cartItems)

    formData.append('customer_address', JSON.stringify(customer_address))
    formData.append('cart_items', JSON.stringify(cartItemsWithTaxablePrice))
    formData.append('tax_adjustment', props.checkout.taxReduction)

    // if portal is multi address, send address selections
    if (props.portal.multiple_address_shipping) {
      formData.append('multi_address_selection', JSON.stringify(props.checkout.multiAddressSelection))
    }

    if (props.appliedPromotions.length > 0) {
      formData.append('promotion_id', props.appliedPromotions[0].promotion_id)
      formData.append('subtotal_reduction', props.checkout.subtotalReduction)
      formData.append('shipping_reduction', props.checkout.shippingReduction)
      formData.append('product_reduction', props.checkout.productReduction)
    }

    if (props.managed_customer_addresses) {
      formData.append('epcid_zero', props.managed_customer_addresses.epcid)
    }

    props
      .fetchTax(formData)
      .then(resp => {
        props.setTaxLoading(false)
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.error('Request aborted:', err.message)
        } else {
          // Only set taxLoading to false for errors other than cancel
          props.setTaxLoading(false)
        }
      })
  }

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' })

  // CALCULATES HEIGHT OF SCROLL DOWN MENU AND ADDS MARGIN ON STICKY SIDEBAR
  const [isZoomedIn, setIsZoomedIn] = useState(false)

  const fixedHeaderElement = document.querySelector('.iron-fixed-header')
  const menuHeight = fixedHeaderElement !== null ? fixedHeaderElement.offsetHeight : '100px'

  const screenHeight = window.screen.availHeight
  const responseHeight = screenHeight !== null && screenHeight <= 770 ? true : false

  const showCheckoutInformation = props.currentLocation.show_checkout_information && props.checkoutForm.length > 0

  return (
    <div>
      <Container className="mt-30 mb-30">
        <Row>
          <Col xs={12} md={7} lg={8}>
            {showCheckoutInformation ? <CheckoutInformation /> : null}

            <CheckoutErrorsPanel />

            <CheckoutAddressSelection />

            {props.cart_loading ? <LoadingSpinner /> : <CheckoutCreditBalances />}

            <CheckoutCardPanel extraFields={props.currentUser.run_automation_and_extra_fields} />

            <CartItemsList isCheckout={true} />
          </Col>

          <Col xs={12} md={5} lg={4} className="right-panel-promotions-summary">
            <Sticky
              className="sticky-right-panel"
              topOffset={0}
              stickyStyle={{
                top: menuHeight >= 90 ? '160px' : '100px',
                zIndex: 2
              }}
              disabled={isTabletOrMobile || responseHeight || isZoomedIn}
            >
              <PromotionsPanel />

              <OrderSummary isCheckout={true} />
            </Sticky>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    checkout: state.checkout,
    errors: state.errors,
    portal: state.portal,
    userCredits: state.userCredits,
    customer_addresses: Object.values(state.customer_addresses),
    managed_customer_addresses: state.managed_customer_addresses,
    location: state.locations[0],
    currentLocation: state.currentLocation,
    cart_multiple_managed_customer_addresses: state.cart_multiple_managed_customer_addresses,
    appliedPromotions: state.checkout.appliedPromotions,
    cartItems: state.cartItems,
    selectedCustomerAddress: state.checkout.selectedCustomerAddress,
    cart_loading: state.checkout.cart_loading,
    tax: state.tax,
    checkoutErrors: state.checkout.errors,
    currentUser: state.currentUser,
    subtotal: state.checkout.subtotal,
    checkoutForm: state.checkoutForm
  }
}

export default connect(mapStateToProps, {
  fetchTax,
  fetchShippingQuote,
  setSubtotal,
  createToken,
  setErrors,
  setShippingLoading,
  checkoutOrderCleanup,
  setCheckoutLoading,
  clearPromotions,
  fetchCartItems,
  setTaxLoading,
  fetchUserCredit,
  fetchUser,
  returnCartItemsRequireApprovalError
})(CheckoutPage)
