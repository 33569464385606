import moment from 'moment'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Button, Label } from 'reactstrap'
import {
  fetchCustomerAddresses,
  fetchDPPreview,
  clearDpPreview,
  dispatchSweetAlert,
  clearSweetAlert
} from '../../../actions'
import brandit_rails from '../../../api/brandit_rails'
import { resolveAddresses } from '../../../util/utils.js'
import InlineContentLoader2 from '../../global/loaders/InlineContentLoader2'
import { updateDigitalProofingSelection } from '../productPageFunctions'
import BusinessCardForm from './BusinessCardFormNew'
import DigitalProofingForm from './DigitalProofingForm'
import DigitalProofingFormDynamic from './DigitalProofingFormDynamic'
import FormAddressWrapper from './FormAddressWrapper'
import WarningIcon from './WarningIcon.js'

const artworkSelectOptions = artworks =>
  artworks
    .sort((a, b) => a.order_artwork - b.order_artwork)
    .map((artwork, i) => {
      return (
        <option key={i} value={artwork.artwork_id}>
          {artwork.name}
        </option>
      )
    })

// re-formats a managed address to have standard fields and prefies ID with "managed"
const reformatManagedAddresses = managedAddresses => {
  return managedAddresses.map(address => {
    const formattedAddress = {
      ...address,
      title: address.name,
      full_name: '',
      address_line_1: address.address1,
      address_line_2: address.address2,
      city: address.city,
      state: address.state,
      zip_code: address.zip
    }

    if (address.address1) {
      return {
        ...formattedAddress,
        id: `managed${address.id}`
      }
    } else {
      return formattedAddress
    }
  })
}

const DigitalProofingAndArtworkForm = ({
  product,
  quantity,
  isBundleProduct,
  addProductToBasket,
  setSelectedArtwork,
  setSelectedCoupon,
  setSelectedAddOn,
  setSelectedAddOn2,
  collapseDpForm,
  selectedArtwork,
  selectedCoupon,
  selectedAddOn,
  selectedAddOn2,
  clearDpPreview,
  proofing_preview,
  currentUser,
  portal,
  managed_customer_addresses,
  customer_addresses,
  customerGroup,
  fetchDPPreview,
  updateDigitalProofingSelection,
  dispatchSweetAlert,
  clearSweetAlert,
  fetchCustomerAddresses
}) => {
  const [, setLoading] = useState(false)
  const [previewLoading, setPreviewLoading] = useState(false)
  const [previewFailed, setPreviewFailed] = useState(false)
  const [customerAddresses, setCustomerAddresses] = useState([])
  const [artworks, setArtworks] = useState([])
  const [coupons, setCoupons] = useState([])
  const [addons, setAddons] = useState([])
  const [addons2, setAddons2] = useState([])
  const [selectedCouponId, setSelectedCouponId] = useState('-1')
  const [selectedAddOnId, setSelectedAddOnId] = useState('-1')
  const [selectedAddOnId2, setSelectedAddOnId2] = useState('-1')
  const [selectedArtworkId, setSelectedArtworkId] = useState('-1')
  const [showProofModal, setShowProofModal] = useState(false)
  const [manageAddresses, setManageAddresses] = useState(false)
  const [proof1, setProof1] = useState(false)
  const [proof2, setProof2] = useState(false)
  const retryData = useRef(null)
  const controller = useRef(null)
  const isAlertShowing = useSelector(state => state.sweetAlert.show)

  const fetchPreview = useCallback(
    formData => {
      controller.current = new AbortController()
      return fetchDPPreview(formData, { signal: controller.current.signal })
        .then(() => {
          setPreviewFailed(false)
          setPreviewLoading(false)
        })
        .catch(() => {
          setPreviewLoading(false)
          setPreviewFailed(true)
        })
    },
    [fetchDPPreview, setPreviewLoading, setPreviewFailed]
  )

  const resetAllSelections = () => {
    setSelectedArtworkId('-1')
    setSelectedCouponId('-1')
    setSelectedAddOnId('-1')
    setSelectedAddOnId2('-1')
    setProof1(false)
    setProof2(false)
  }

  // pass to add to cart function. called upon successfully adding to cart
  const addProductCallback = () => {
    setShowProofModal(false)
    resetAllSelections()
    setProof1(false)
    setProof2(false)
  }

  const downloadPdfPreview = () => {
    window.open(proofing_preview.full_pdf.url)
  }

  // clear any existing preview from redux on mount
  useEffect(() => {
    clearDpPreview()
    return () => {
      clearDpPreview()
      setPreviewFailed(false)
      retryData.current = null
    }
  }, [])

  // ARTWORK: set selected artwork when selection changes
  useEffect(() => {
    const aw = artworks.find(a => a.artwork_id === parseInt(selectedArtworkId, 10))
    if (!aw) {
      setSelectedArtwork(false)
    } else {
      setSelectedArtwork(aw)
    }
    // also reset any addresses above the # of supported?
  }, [selectedArtworkId])

  // COUPON: set selected coupon when selection changes
  useEffect(() => {
    const aw = coupons.find(a => a.artwork_id === parseInt(selectedCouponId, 10))
    if (!aw) {
      setSelectedCoupon(false)
    } else {
      setSelectedCoupon(aw)
    }
  }, [selectedCouponId])

  // 1st ADDON: set selected addon when selection changes
  useEffect(() => {
    const aw = addons.find(a => a.artwork_id === parseInt(selectedAddOnId, 10))
    if (!aw) {
      setSelectedAddOn(false)
    } else {
      setSelectedAddOn(aw)
    }
  }, [selectedAddOnId])

  //2nd ADDON:  set selected addon when selection changes
  useEffect(() => {
    const aw = addons2.find(a => a.artwork_id === parseInt(selectedAddOnId2, 10))
    if (!aw) {
      setSelectedAddOn2(false)
    } else {
      setSelectedAddOn2(aw)
    }
  }, [selectedAddOnId2])

  // componentDidMount
  useEffect(() => {
    let resolvedAddresses = []
    const user_id = parseInt(currentUser.id)

    if (!user_id) return

    setLoading(true)
    if (portal.managed_address_book || portal.hybrid_address) {
      const addresses = reformatManagedAddresses(managed_customer_addresses)

      if (portal.hybrid_address) {
        fetchCustomerAddresses(user_id, portal.id).then(() => {
          setLoading(false)
          resolvedAddresses = resolveAddresses(
            customer_addresses,
            addresses,
            portal,
            customerGroup.hide_create_shipping_address
          )
        })
      } else {
        setLoading(false)

        resolvedAddresses = resolveAddresses([], addresses, portal, customerGroup.hide_create_shipping_address)
      }
    } else if (portal) {
      fetchCustomerAddresses(user_id, portal.id).then(() => {
        setLoading(false)
        resolvedAddresses = resolveAddresses(customer_addresses, [], portal, customerGroup.hide_create_shipping_address)
      })
    }

    setCustomerAddresses(resolvedAddresses)
  }, [])

  useEffect(() => {
    const addresses = reformatManagedAddresses(managed_customer_addresses)

    const resolvedAddresses = resolveAddresses(
      customer_addresses,
      addresses,
      portal,
      customerGroup.hide_create_shipping_address
    )

    setCustomerAddresses(resolvedAddresses)
  }, [customer_addresses, managed_customer_addresses])

  // Initialize Artworks & Coupons
  useEffect(() => {
    if (customerGroup.id) {
      const hiddenArtworkList = customerGroup.hidden_artworks_list || []

      let productArtworks = null
      if (product.flags.is_artwork) {
        productArtworks = product.artwork ? product.artwork.nested_data : []
      } else {
        productArtworks = product.digitalProofing.nested_data
      }
      // find artworks that are coupons,
      // that are in product artwork list,
      // and not in customer group's hidden artwork list
      if (customerGroup.id && hiddenArtworkList && productArtworks) {
        const notHiddenArtworks = productArtworks.filter(
          artwork => !hiddenArtworkList.find(art => String(art.id) === String(artwork.id))
        )
        const addedArtworks = product?.digitalProofing?.added_artworks ?? []
        setCoupons(addedArtworks)
        setAddons(notHiddenArtworks.filter(art => art.pdf_type === 'Add-on' && art.added_artwork_index === 1))
        setAddons2(notHiddenArtworks.filter(art => art.pdf_type === 'Add-on' && art.added_artwork_index === 2))
        const filteredArtwork = notHiddenArtworks.filter(art => art.pdf_type === 'Artwork')
        setArtworks(filteredArtwork)
      }
    } else if (isBundleProduct === true) {
      // bundle products have different structure
      const nestedData = product.nested_data && product.nested_data[0] && product.nested_data[0].nested_data
      if (nestedData) {
        setCoupons(nestedData.filter(art => art.pdf_type === 'Coupon'))
        setAddons(nestedData.filter(art => art.pdf_type === 'Add-on' && art.added_artwork_index === 1))
        setAddons2(nestedData.filter(art => art.pdf_type === 'Add-on' && art.added_artwork_index === 2))
        setArtworks(nestedData.filter(art => art.pdf_type === 'Artwork'))
      }
    }
  }, [product])

  useEffect(() => {
    if (artworks.length === 1) {
      setSelectedArtworkId(artworks[0].artwork_id)
    }
  }, [artworks])

  useEffect(() => {
    if (coupons.length === 1) {
      setSelectedCouponId(coupons[0].artwork_id)
    }
  }, [coupons])

  useEffect(() => {
    if (addons.length === 1) {
      setSelectedAddOnId(addons[0].artwork_id)
    }
  }, [addons])

  useEffect(() => {
    if (addons2.length === 1) {
      setSelectedAddOnId2(addons2[0].artwork_id)
    }
  }, [addons2])

  const handleAddProduct = () => {
    // for bundle products, we don't add to cart, we have to save it to redux until all proofs are generated
    if (isBundleProduct) {
      updateDigitalProofingSelection({
        bundleProduct: product,
        proofingPreview: proofing_preview
      }).then(() => {
        setShowProofModal(false)
        collapseDpForm(product.bundle_group_product_id)
        resetAllSelections()
      })
    } else {
      addProductToBasket(product, quantity, proofing_preview, addProductCallback, () => setShowProofModal(false))
    }
  }

  // This is a callback passed to children so they can each set their own formData to send to API
  const formComplete = (incomingFormData, isRetry = false) => {
    setPreviewLoading(true)
    setPreviewFailed(false)
    setShowProofModal(true)

    if (isRetry) {
      fetchPreview(retryData.current)
      return
    }

    const data = {
      product_id: isBundleProduct ? product.product_id : product.digitalProofing.product_id,
      artwork_id: isBundleProduct ? selectedArtworkId : selectedArtwork.artwork_id,
      coupon_id: selectedCouponId === '-1' ? null : selectedCouponId,
      addon_id: selectedAddOnId === '-1' ? null : selectedAddOnId,
      addon_id2: selectedAddOnId2 === '-1' ? null : selectedAddOnId2
    }

    incomingFormData.append('product_id', data.product_id ? data.product_id : '')
    incomingFormData.append('artwork_id', data.artwork_id ? data.artwork_id : '')
    incomingFormData.append('coupon_id', data.coupon_id ? data.coupon_id : '')
    incomingFormData.append('addon_id', data.addon_id ? data.addon_id : '')
    incomingFormData.append('addon_id2', data.addon_id2 ? data.addon_id2 : '')
    incomingFormData.append('approved_date', moment())
    if (retryData.current === null) {
      retryData.current = new FormData()
      for (const [key, value] of incomingFormData.entries()) {
        retryData.current.append(key, value)
      }
    }

    fetchPreview(incomingFormData, isRetry)
  }

  const cancelProofConfirmed = () => {
    controller.current?.abort()
    setShowProofModal(false)
    resetAllSelections()
    retryData.current = null
    if (previewFailed || !proofing_preview.id) {
      clearDpPreview()
      return
    }
    brandit_rails.post(`/product/digital_proofing_products/destroy/${proofing_preview.id}`)
    clearDpPreview()
  }

  const sweetAlertProps = useMemo(
    () => ({
      title: 'Are you sure you want to cancel?',
      alertMessage: 'You will lose any selections you have made.',
      type: 'warning',
      showCancelButton: true,
      confirmBtnText: 'Yes, cancel',
      cancelBtnText: 'No, keep it',
      onConfirm: cancelProofConfirmed,
      onCancel: clearSweetAlert
    }),
    [cancelProofConfirmed, clearSweetAlert]
  )

  // update sweetalert when proofing_preview.id changes so it destroys the preview if generated after sweet alert shown
  useEffect(() => {
    if (isAlertShowing && proofing_preview.id) {
      dispatchSweetAlert(sweetAlertProps)
    }
  }, [isAlertShowing, proofing_preview.id])

  const cancelProof = () => {
    if (!previewLoading) {
      cancelProofConfirmed()
      return
    }

    dispatchSweetAlert(sweetAlertProps)
  }

  const toggleProof = (e, num) => {
    if (num === 1) {
      setProof1(!proof1)
    } else {
      setProof2(!proof2)
    }
  }

  const PreviewModal = () => {
    const errorText =
      "We encountered an issue while loading your proof. Please try refreshing the page or click 'Retry'. If the problem persists,"

    return (
      <Modal isOpen={showProofModal} className="preview-modal">
        <ModalHeader
          close={
            <button className="close" onClick={cancelProof} type="button">
              &times;
            </button>
          }
        >
          {previewFailed ? 'Error Loading Proof' : 'Proof Preview'}
        </ModalHeader>

        <ModalBody>
          {proofing_preview.full_pdf.url ? (
            proofing_preview.pdf_size < 20000000 ? (
              <object
                width="100%"
                // height="500"
                height="100vh"
                style={{ minHeight: '500px' }}
                type="application/pdf"
                data={
                  proofing_preview.full_pdf.url
                    ? `${proofing_preview.full_pdf.url}?#zoom=100&scrollbar=0&toolbar=0&navpanes=0`
                    : ''
                }
              >
                <p className="d-inline align-items-center justify-content-center lead">{errorText}</p>{' '}
                <a href="/contact-us" className="lead link-underline-primary">
                  contact support
                </a>
                .
              </object>
            ) : (
              <div className="mb-4">
                <p>
                  {' '}
                  Your file is too large to generate the preview, please click the button below to download a preview of
                  your proof.
                </p>
                <Button color="success" onClick={downloadPdfPreview}>
                  Click Here To Download
                </Button>
              </div>
            )
          ) : (
            <div className="text-center">
              {previewFailed ? (
                <>
                  <div>
                    <WarningIcon />
                  </div>
                  <p className="d-inline align-items-center justify-content-center lead">{errorText}</p>{' '}
                  <a href="/contact-us" className="lead link-underline-primary">
                    contact support
                  </a>
                  .
                </>
              ) : (
                <>
                  <strong>Some Previews May Take Up to a Minute to Load Due to File Size.</strong>
                  <InlineContentLoader2 />
                </>
              )}
            </div>
          )}

          {!previewFailed && (
            <>
              <FormGroup
                check
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  flexDirection: 'row',
                  alignContent: 'baseline'
                }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    name={`proof1`}
                    id={`proof1`}
                    value={proof1}
                    onChange={e => toggleProof(e, 1)}
                    style={{ index: '20' }}
                  />{' '}
                  {portal[`digital_proofing_text_1`]}
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name={`proof2`}
                    id={`proof2`}
                    value={proof2}
                    onChange={e => toggleProof(e, 2)}
                  />{' '}
                  {portal[`digital_proofing_text_2`]}
                </Label>
              </FormGroup>
            </>
          )}
        </ModalBody>

        <ModalFooter>
          {!previewFailed && (
            <Button className="btn mf-outline-btn" onClick={cancelProof}>
              Cancel
            </Button>
          )}

          {proof1 && proof2 && !previewFailed && (
            <Button
              className="btn mf-primary-btn"
              disabled={previewLoading || previewFailed}
              onClick={handleAddProduct}
            >
              {isBundleProduct ? 'Approve Proof' : 'Add to Cart'}
            </Button>
          )}
          {previewFailed && (
            <Button
              className="btn mf-primary-btn"
              disabled={false}
              onClick={() => formComplete(retryData.current, true)}
            >
              Retry
            </Button>
          )}
        </ModalFooter>
      </Modal>
    )
  }

  const FormSwitch = () => {
    const dpFormProps = {
      complete: formComplete,
      noCouponSelected: !selectedCoupon,
      noAddonSelected: !selectedAddOn,
      noAddonSelected2: !selectedAddOn2,
      customer_addresses,
      selectedArtwork,
      selectedArtworkId,
      selectedCouponId,
      selectedAddOnId,
      selectedAddOnId2,
      portal
    }

    // for bundle products, item type must be ascertained from other data
    let dpItemType = null

    if (selectedArtwork?.is_new_pdf_method && !!product.is_digital_proofing) {
      return (
        <DigitalProofingFormDynamic
          {...dpFormProps}
          // artworks={artworks}
          artwork={selectedArtwork}
          requiresCoupon={coupons.length > 0}
          selectedCoupon={selectedCoupon}
          requiresAddOn={addons.length > 0}
          requiresAddOn2={addons2.length > 0}
          selectedAddOn={selectedAddOn}
          selectedAddOn2={selectedAddOn2}
        />
      )
    }

    dpItemType = product.item_type
    switch (dpItemType) {
      case 'Flyer':
      case 'Door Hanger':
      case 'Postcard':
        return (
          <DigitalProofingForm
            {...dpFormProps}
            selectedCoupon={selectedCoupon}
            selectedCouponId={selectedCouponId}
            selectedAddOnId={selectedAddOnId}
            selectedAddOnId2={selectedAddOnId2}
            resolvedAddresses={customerAddresses}
          />
        )
      case 'Business Card':
        return (
          <BusinessCardForm
            {...dpFormProps}
            artwork={
              isBundleProduct
                ? product.nested_data[0].nested_data.find(aw => aw.artwork_id === selectedArtworkId)
                : selectedArtwork
            } // TODO: change to selectedArtwork
            resolvedAddresses={customerAddresses}
          />
        )
      default:
        return <h5></h5>
    }
  }

  const handleSelectArtwork = e => {
    const artworkId = parseInt(e.target.value, 10)
    setSelectedArtworkId(artworkId)
  }

  const handleSelectCoupon = e => {
    const couponId = parseInt(e.target.value, 10)
    setSelectedCouponId(couponId)
  }
  const handleSelectAddOn = e => {
    const addOnId = parseInt(e.target.value, 10)
    setSelectedAddOnId(addOnId)
  }

  const handleSelectAddOn2 = e => {
    const addOnId2 = parseInt(e.target.value, 10)
    setSelectedAddOnId2(addOnId2)
  }

  const CouponDropdown = (product, selectedCoupon) => {
    const couponOptions = artworkSelectOptions(coupons)
    return (
      <>
        {/* Dropdown add coupon, not available for Business Cards and certain DP */}
        {product.item_type === 'Business Card' ? null : (
          <div className="w-100">
            {coupons && coupons.length === 1 ? (
              <div className="mb-20">
                <br />
                <h6>{product.coupon_alias}</h6>
                {coupons[0].name}
              </div>
            ) : coupons && coupons.length > 0 ? (
              <>
                <br />
                <h6>{product.coupon_alias}</h6>
                <Input value={selectedCouponId} onChange={handleSelectCoupon} type="select">
                  {(product.item_type === 'Postcard' ||
                    product.item_type === 'Flyer' ||
                    product.item_type === 'Door Hanger') && (
                    <option disabled value="-1">
                      {`Please select the desired  ${product.coupon_alias}`}
                    </option>
                  )}
                  {couponOptions}
                </Input>
              </>
            ) : null}
          </div>
        )}
        {selectedCoupon?.image2 && (
          <>
            <br />
            <img className="mb-10 artwork-thumbnail" src={selectedCoupon.image2} alt="artwork-thumbnail" />
          </>
        )}
      </>
    )
  }

  const AddOnDropdown = (product, selectedAddOn) => {
    const addOnOptions = artworkSelectOptions(addons)

    return (
      <>
        {/* Dropdown add addon not available for Business Cards and certain DP */}
        {product.item_type === 'Business Card' ? null : (
          <div className="w-100">
            {/* ADD-ONS 1 */}
            {addons && addons.length === 1 ? (
              <div className="mb-20">
                <br />
                <h6>{product.addon_alias}</h6>
                {addons[0].name}
              </div>
            ) : addons && addons.length > 0 ? (
              <>
                <br />
                <h6>{product.addon_alias}</h6>
                <Input value={selectedAddOnId} onChange={handleSelectAddOn} type="select" key="addon_alias">
                  {(product.item_type === 'Postcard' ||
                    product.item_type === 'Flyer' ||
                    product.item_type === 'Door Hanger') && (
                    <option disabled value="-1">
                      {`Please select the desired  ${product.addon_alias}`}
                    </option>
                  )}
                  {addOnOptions}
                </Input>
              </>
            ) : null}
          </div>
        )}
        {selectedAddOn?.image2 && (
          <>
            <br />
            <img className="mb-10 artwork-thumbnail" src={selectedAddOn.image2} alt="artwork-thumbnail" />
          </>
        )}
      </>
    )
  }

  const AddOnDropdown2 = (product, selectedAddOn2) => {
    const addOnOptions2 = artworkSelectOptions(addons2)

    return (
      <>
        {/* Dropdown add addon not available for Business Cards and certain DP */}
        {product.item_type === 'Business Card' ? null : (
          <div className="w-100">
            {/* ADD-ONS 2 */}
            {addons2 && addons2.length === 1 ? (
              <div className="mb-20">
                <br />
                <h6>{product.addon_alias2}</h6>
                {addons2[0].name}
              </div>
            ) : addons2 && addons2.length > 0 ? (
              <>
                <br />
                <h6>{product.addon_alias2}</h6>
                <Input value={selectedAddOnId2} onChange={handleSelectAddOn2} type="select" key="addon_alias2">
                  {(product.item_type === 'Postcard' ||
                    product.item_type === 'Flyer' ||
                    product.item_type === 'Door Hanger') && (
                    <option disabled value="-1">
                      {`Please select the desired  ${product.addon_alias2}`}
                    </option>
                  )}
                  {addOnOptions2}
                </Input>
              </>
            ) : null}
          </div>
        )}
        {selectedAddOn2?.image2 && (
          <>
            <br />
            <img className="mb-10 artwork-thumbnail2" src={selectedAddOn2.image2} alt="artwork-thumbnail" />
          </>
        )}
      </>
    )
  }

  const ArtworkDropdown = (product, selectedArtwork) => {
    if (product.allow_artwork || (product.flags && product.flags.is_digital_proofing) || product.is_digital_proofing) {
      const artworkOptions = artworkSelectOptions(artworks)
      return (
        <div className="mb-20 w-100">
          <h6>{product.artwork_alias}</h6>
          {artworks && artworks.length === 1 ? (
            <div className="mb-20">{artworks[0].name}</div>
          ) : artworks && artworks.length > 0 ? (
            <Input type="select" value={selectedArtworkId} onChange={handleSelectArtwork}>
              <option disabled value={'-1'}>
                {`Please select the desired  ${product.artwork_alias}`}
              </option>
              {artworkOptions}
            </Input>
          ) : (
            <div className="mb-20">No artworks are available for this product</div>
          )}
          {selectedArtwork?.image2 && (
            <>
              <br />
              <img className="mb-20 artwork-thumbnail" src={selectedArtwork.image2} alt="artwork-thumbnail" />
            </>
          )}
        </div>
      )
    }
  }

  return (
    <div className={isBundleProduct ? 'description-container m-0 p-0' : 'description-container'}>
      {ArtworkDropdown(product, selectedArtwork)}

      {/* Coupons only for DP */}
      {product.flags && product.flags.is_artwork ? null : CouponDropdown(product, selectedCoupon, selectedArtwork)}
      {/* First Add-ons  */}
      {product.flags && product.flags.is_artwork ? null : AddOnDropdown(product, selectedAddOn, selectedArtwork)}
      {/* Second Add-ons  */}
      {product.flags && product.flags.is_artwork && addons2.length > 0
        ? null
        : AddOnDropdown2(product, selectedAddOn2, selectedArtwork)}

      {/* SN CHECK */}
      <div className={selectedArtwork ? 'mb-20 mt-20' : 'd-none'}>
        <div style={{ textAlign: 'right' }}>
          {artworks.length > 1 || coupons.length > 1 || addons.length > 1 ? (
            <Button className="btn mf-warning-btn" onClick={resetAllSelections}>
              Reset My Selections
            </Button>
          ) : null}
        </div>
        {(!portal.managed_address_book || portal.hybrid_address) &&
          product.flags &&
          product.flags.is_digital_proofing &&
          !(selectedArtwork && selectedArtwork.is_new_pdf_method) && (
            <FormAddressWrapper manageAddresses={manageAddresses} setManageAddresses={setManageAddresses} />
          )}

        {FormSwitch()}
      </div>

      {PreviewModal()}
    </div>
  )
}

DigitalProofingAndArtworkForm.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number,
  isBundleProduct: PropTypes.bool,
  addProductToBasket: PropTypes.func,
  setSelectedArtwork: PropTypes.func,
  setSelectedCoupon: PropTypes.func,
  setSelectedAddOn: PropTypes.func,
  setSelectedAddOn2: PropTypes.func,
  collapseDpForm: PropTypes.func,
  selectedArtwork: PropTypes.object,
  selectedCoupon: PropTypes.object,
  selectedAddOn: PropTypes.object,
  selectedAddOn2: PropTypes.object,
  clearDpPreview: PropTypes.func,
  proofing_preview: PropTypes.object,
  currentUser: PropTypes.object,
  portal: PropTypes.shape({
    id: PropTypes.number,
    hybrid_address: PropTypes.bool,
    digital_proofing_text_1: PropTypes.string,
    digital_proofing_text_2: PropTypes.string,
    managed_address_book: PropTypes.bool
  }),
  customer_addresses: PropTypes.array,
  managed_customer_addresses: PropTypes.array,
  customerGroup: PropTypes.shape({
    id: PropTypes.number,
    hide_create_shipping_address: PropTypes.bool,
    hidden_artworks_list: PropTypes.array
  }),
  fetchDeliveryAddresses: PropTypes.func,
  fetchDPPreview: PropTypes.func,
  updateDigitalProofingSelection: PropTypes.func,
  dispatchSweetAlert: PropTypes.func,
  clearSweetAlert: PropTypes.func,
  fetchCustomerAddresses: PropTypes.func
}

const mapStateToProps = state => {
  return {
    portal: state.portal,
    customer_addresses: Object.values(state.customer_addresses).filter(address => address.address_type === 'delivery'),
    proofing_preview: state.proofing_preview,
    currentLocation: state.currentLocation,
    customerGroup: state.customerGroup,
    managed_customer_addresses: state.managed_customer_addresses,
    currentUser: state.currentUser
  }
}

export default connect(mapStateToProps, {
  fetchCustomerAddresses,
  fetchDPPreview,
  clearDpPreview,
  updateDigitalProofingSelection,
  dispatchSweetAlert,
  clearSweetAlert
})(DigitalProofingAndArtworkForm)
